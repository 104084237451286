.report-filter-loading{
    height:38px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.highlight-button::before {
  content: '';
  border-radius: 1000px;
  border: 6px solid #03a9f4;
  box-shadow: 0 0 60px #03a9f4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
} 

.highlight-button::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid  #03a9f4;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

.highlight-button:hover::after, .highlight-button:focus::after {
  animation: none;
  display: none;
}
  

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 150px;
    height: 150px;
    opacity: 0;
  }
}