.dnd-step-box {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    padding: 7px;
    border: 1px black solid;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
    white-space: nowrap;
    cursor: grab;
    background-color:skyblue;
    font-weight:700;
    width:150px;
}

.dnd-step-box-disabled {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    padding: 7px;
    border: 0.5px grey dotted;
    border-radius: 5px;
    margin: 0px 10px 0px 0px;
    white-space: nowrap;
    cursor: no-drop;
    background-color: #d3d3d342;
    font-weight:700;
    width:150px;
}

.dnd-step-box-key {
    display:flex;
    align-items:center;
}

.workflow-steps-settings-dnd .dndPlaceholder {
    padding: 7px;
    border: 0.5px grey dotted;
    border-radius: 5px;
    width: 150px!important;
    background-color: lightgrey;
    margin: 0px 10px 0px 0px;
    white-space: nowrap;
}

.workflow-steps-settings-dnd .dndDraggingSource {
    display: none;
}

.workflow-steps-settings-dnd .dndDragging {
    cursor: grabbing !important;
}