.job-match-disabled {
    background-color: lightgrey!important;
}

.job-match-restricted-access {
    background-color: #1ccc111f !important;
}

.job-match-score {
    /*background: green;*/
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    display: inline-block;
    width: 45px;
    text-align: center;
}

.candidate-job-matches-actions-divider {
    height: 2px;
    overflow: hidden;
    background-color: #b5b5b5;
}

.job-match-legend {
    display: inline-block;
    width: 13px;
    height: 12px;
    border:1px solid black;
    border-radius: 3px;
    margin-right: 5px;
    position: relative;
    top: 3px;
}

.job-match-disabled-legend {
    background-color: #a7a1a1 !important;
}


.job-match-restricted-access-legend {
    background-color: #34a131ab;
}

.job-match-legend-info {
    font-size: 13px;
}

.table>thead>tr>th {
    padding: 10px 8px;
}

#job-matches-table_paginate .pagination li {
    padding: 0px !important;
}

#job-matches-table_paginate .pagination li:hover{
    background-color: none !important;
    background: none !important;
    border: 1px solid transparent !important;
}

.current-job-matches-primary-hiring-manager-icon {
    font-size: 12px;
    position: relative;
    bottom: 5px;
    color: #03a9f4;
    font-family: cursive;
    font-weight: 600;
}

.current-job-match-shared-requisition-color {
    color:  #7541e0;
}

.candidate-current-job-vendor-recruiter {
    color: #7541e0;
}