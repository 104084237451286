
 svg {
    width: 20px;
    height: 20px;
    fill: #bfc8c9;
}
.svg-active {
    fill: #fff;
}
.svg-inactive {
    fill: #bfc8c9;
}
.flow {
    display: none;
}
.progress-hidden {
    visibility: hidden;
}
#flow-meeting {
    min-width: 320px;
}
@media (max-width: 575px) {
    #tile-area {
        display: flex;
        flex-wrap: wrap;
   }
    #tile-area > div {
        position: relative !important;
        top: auto !important;
        right: auto !important;
        bottom: auto !important;
        left: auto !important;
        height: auto !important;
   }
    #tile-area > div.video-tile {
        width: 50% !important;
        padding-bottom: 33% !important;
   }
    #tile-area > div.content-share-tile {
        order: -1;
        width: 100% !important;
        padding-bottom: 66% !important;
        margin-bottom: 2rem;
   }
    #tile-area > div > video {
        object-fit: cover;
   }
    #tile-area > div > div, #tile-area > div > button {
        display: none !important;
   }
    #content-share-video {
        display: none !important;
   }
    #meeting-container {
        height: auto !important;
   }
    #roster-tile-container {
        height: auto !important;
   }
    #roster-message-container {
        height: auto !important;
   }
    #tile-container {
        height: auto !important;
   }
    .bs-component {
        max-height: 210px;
        height: auto !important;
   }
    .message {
        max-height: 210px;
        height: 210px !important;
   }
    .video-preview {
        width: auto;
        height: 82px;
        margin-left: 35%;
        margin-top: 7px;
   }
}
.video-preview {
    height: 82px;
    margin-left: 0;
    margin-top: 0;
}
.markdown {
    margin-top: 0;
    margin-bottom: 0;
}
a.markdown:link {
    color: #278990;
}
a.markdown:visited {
    color: #278990;
}
a.markdown:hover {
    color: #278990;
}
a.markdown:active {
    color: #278990;
}
.message-bubble-self {
    font-size: 12px;
    border-radius: 5px;
    background-color: #eef8f8;
    border-width: 1px;
    border-style: solid;
    border-color: #86d1d7;
    padding: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}
.message-bubble-other {
    font-size: 12px;
    border-radius: 5px;
    background-color: #f1f1f1;
    border-style: none;
    padding: 0.5rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-top: 0rem;
    margin-bottom: 0.5rem;
}
.message-bubble-sender {
    font-size: 12px;
    margin-left: 1.3rem;
    margin-right: 1rem;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    padding: 0.2rem 0rem;
}
.v-grid {
    display: grid;
    height: 100%;
    width: 100%;
    grid-gap:1px;
}
.v-grid.size-1 {
    grid-template: 1fr / 1fr;
}
.v-grid.size-1 .video-tile {
    /* height: 0; */
    padding-bottom: calc(100% / (16 / 9));
}
.v-grid.size-2 {
    /* grid-template: repeat(2, 1fr) / 1fr; */
    grid-template: 1fr/repeat(2,1fr);
}
.v-grid.size-3 {
    grid-template: 1fr / repeat(3, 1fr);
}

.v-grid.size-4 {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
}
.v-grid.size-5, .v-grid.size-6 {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
}
.v-grid.size-7, .v-grid.size-8 {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
}
.v-grid.size-9 {
    grid-template: repeat(3, 1fr) / repeat(3, 1fr);
} 
.v-grid.size-10.v-grid.size-11, .v-grid.size-12 {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
}
.v-grid.size-13, .v-grid.size-14, .v-grid.size-15 {
    grid-template: repeat(5, 1fr) / repeat(3, 1fr);
}
.v-grid.size-16 {
    grid-template: repeat(6, 1fr) / repeat(3, 1fr);
}

.v-grid.featured {
    grid-template: 1fr / 1fr;
    grid-template-areas: 'ft';
}
.v-grid.featured.size-2 {
    /* grid-template: repeat(4, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft' 'ft ft' 'ft ft'; */
    grid-template: 1fr / repeat(2,1fr);
    grid-template-areas: 'ft' ;
}

.v-grid.featured.size-3 {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft' 'ft ft' 'ft ft';
    /* grid-template: 1fr / repeat(3,1fr);
    grid-template-areas: 'ft' ; */
}

.v-grid.featured.size-4 {
    grid-template: repeat(4, 1fr) / repeat(3, 1fr);
    grid-template-areas: 'ft ft ft' 'ft ft ft' 'ft ft ft';
}

.v-grid.featured.size-5 {
    grid-template: repeat(6, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft' 'ft ft' 'ft ft' 'ft ft';
}

.v-grid.featured.size-6, .v-grid.featured.size-7 {
    grid-template: repeat(6, 1fr) / repeat(3, 1fr);
    grid-template-areas: 'ft ft ft' 'ft ft ft' 'ft ft ft' 'ft ft ft';
}
.v-grid.featured.size-8, .v-grid.featured.size-9 {
    grid-template: repeat(6, 1fr) / repeat(4, 1fr);
    grid-template-areas: 'ft ft ft ft' 'ft ft ft ft' 'ft ft ft ft' 'ft ft ft ft';
}
.v-grid.featured.size-10, .v-grid.featured.size-11, .v-grid.featured.size-12, .v-grid.featured.size-13 {
    grid-template: repeat(7, 1fr) / repeat(6, 1fr);
    grid-template-areas: 'ft ft ft ft ft ft' 'ft ft ft ft ft ft' 'ft ft ft ft ft ft' 'ft ft ft ft ft ft' 'ft ft ft ft ft ft';
}
.v-grid.featured.size-14, .v-grid.featured.size-15, .v-grid.featured.size-16, .v-grid.featured.size-17 {
    grid-template: repeat(7, 1fr) / repeat(8, 1fr);
    grid-template-areas: 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft' 'ft ft ft ft ft ft ft ft';
}

/* half width start */
.v-grid.size-2.half {
    grid-template: repeat(2, 1fr) / 1fr;
}
.v-grid.size-3.half {
    grid-template: repeat(3, 1fr) / 1fr;
}
.v-grid.size-4.half {
    grid-template: repeat(4, 1fr) / 1fr;
}
.v-grid.size-5.half, .v-grid.size-6.half {
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
}

.v-grid.size-7.half, .v-grid.size-8.half {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
}

.v-grid.size-9.half, .v-grid.size-10.half{
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
}

.v-grid.size-11.half, .v-grid.size-12.half {
    grid-template: repeat(6, 1fr) / repeat(2, 1fr);
}

.v-grid.size-13.half, .v-grid.size-14.half {
    grid-template: repeat(7, 1fr) / repeat(2, 1fr);
}

.v-grid.size-15.half, .v-grid.size-16.half {
    grid-template: repeat(8, 1fr) / repeat(2, 1fr);
}

.v-grid.featured.size-1.half {
    grid-template: 1fr / 1fr;
    grid-template-areas: 'ft';
}

.v-grid.featured.size-2.half {
    grid-template: repeat(2, 1fr) / 1fr;
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-3.half {
    grid-template: repeat(2, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-4.half, .v-grid.featured.size-5.half {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft' 'ft ft';
}

.v-grid.featured.size-6.half{
    grid-template: repeat(3, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-7.half {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-8.half {
    grid-template: repeat(4, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-9.half {
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-10.half {
    grid-template: repeat(5, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-11.half{
    grid-template: repeat(6, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-12.half {
    grid-template: repeat(6, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-13.half {
    grid-template: repeat(7, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-14.half{
    grid-template: repeat(7, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-15.half {
    grid-template: repeat(8, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

.v-grid.featured.size-16.half{
    grid-template: repeat(8, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft';
}

.v-grid.featured.size-17.half{
    grid-template: repeat(9, 1fr) / repeat(2, 1fr);
    grid-template-areas: 'ft ft';
}

/* half width end */



@media screen and (max-width: 1200px) {
    .v-grid.size-2 {
        grid-template: repeat(2, 1fr) / 1fr;
   }
    .v-grid.size-3 {
        grid-template: repeat(3, 1fr) / 1fr;
   }
    .v-grid.size-4 {
        grid-template: repeat(4, 1fr) / 1fr;
   }
    .v-grid.size-5, .v-grid.size-6, .v-grid.size-7, .v-grid.size-8 {
        grid-template: repeat(4, 1fr) / repeat(2, 1fr);
   }
    .v-grid.size-9, .v-grid.size-10, .v-grid.size-11, .v-grid.size-12 {
        grid-template: repeat(6, 1fr) / repeat(2, 1fr);
   }
    .v-grid.size-13, .v-grid.size-14, .v-grid.size-15, .v-grid.size-16 {
        grid-template: repeat(8, 1fr) / repeat(2, 1fr);
   }
    .v-grid.featured.size-1 {
        grid-template: 1fr / 1fr;
        grid-template-areas: 'ft';
   }
    .v-grid.featured.size-2, .v-grid.featured.size-3, .v-grid.featured.size-4, .v-grid.featured.size-5 {
        grid-template: repeat(4, 1fr) / repeat(2, 1fr);
        grid-template-areas: 'ft ft' 'ft ft';
   }
    .v-grid.featured.size-6, .v-grid.featured.size-7 {
        grid-template: repeat(4, 1fr) / repeat(3, 1fr);
        grid-template-areas: 'ft ft ft' 'ft ft ft';
   }
    .v-grid.featured.size-8, .v-grid.featured.size-9 {
        grid-template: repeat(6, 1fr) / repeat(4, 1fr);
        grid-template-areas: 'ft ft ft ft ft' 'ft ft ft ft ft' 'ft ft ft ft ft' 'ft ft ft ft ft';
   }
    .v-grid.featured.size-10, .v-grid.featured.size-11, .v-grid.featured.size-12, .v-grid.featured.size-13 {
        grid-template: repeat(8, 1fr) / repeat(4, 1fr);
        grid-template-areas: 'ft ft ft ft ft' 'ft ft ft ft ft' 'ft ft ft ft ft' 'ft ft ft ft ft' 'ft ft ft ft ft';
   }
    .v-grid.featured.size-12, .v-grid.featured.size-13, .v-grid.featured.size-14, .v-grid.featured.size-15, .v-grid.featured.size-16, .v-grid.featured.size-17 {
        grid-template: repeat(8, 1fr) / repeat(4, 1fr);
        grid-template-areas: 'ft ft ft ft' 'ft ft ft ft' 'ft ft ft ft' 'ft ft ft ft';
   }
}
.video-tile {
    position: relative;
    display: none;
    font-size: 18px;
    overflow: hidden;
}
.video-tile.active {
    display: block;
    /* min-height: 300px;  */
}
.video-tile.speaking {
    border: 4px solid #1cd41c; 
}
.video-tile.featured {
    grid-area: ft;
}
.video-tile.content {
    grid-area: ft;
}
/* .video-tile.content .video-tile-video {
    object-fit: contain !important;
    background-color: #313030;
} */
.video-tile.primary {
    border: 5px solid pink;
}
.video-tile-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}
.video-tile-nameplate {
    position: absolute;
    bottom: 10px;
    left: 10px;
    color: #fff;
    text-shadow: 0px 0px 5px black;
    /* letter-spacing: 0.1em; */
    font-size: 17px;
}

.video-tile-fullscreen {
    position: absolute;
    top:10px;
    right:10px;
    text-shadow: 0px 0px 5px black;
    letter-spacing: 0.1em;
    color:white;
    cursor: pointer;
    /* padding:10px;
    border: 1px solid white; */
}
.video-tile-pause {
    position: absolute;
    display: inline-block;
    bottom: 10px;
    right: 10px;
    margin: 0;
    padding: 0;
    border: none;
    color: #fff;
    text-shadow: 0px 0px 5px black;
    letter-spacing: 0.1em;
    outline: none;
    background: none;
}

.conf-close-adjacent-view {
    position: absolute;
    right: 15px;
    top: 12px;
    z-index: 2;
    cursor: pointer;
}

.video-fixed-on-scroll {
    position: fixed;
    z-index: 1;
    top:0;
    left:5px;
    transform: translateY(100%);
    width: 260px;
    height: 150px;
    animation: fade-in-up .25s ease forwards;
  }

.action-enabled-indicator{
    display: inline-block;
    background-color: lightgreen;
    width: 8px;
    height: 8px;
    position: absolute;
    z-index: 10000;
    border-radius: 50%;
  }

.action-enabled-indicator-microphone{
    top: 1px;
    right: 9px;
  }
.action-enabled-indicator-camera{
    top: -2px;
    right: 7px;
}

.action-enabled-indicator-speaker{
    top: -1px;
    right: 11px;
}

.action-enabled-indicator-share{
    top: -1px;
    right: 24px;
}
  .text-green{
      color: lightgreen;
  }

  .vh-60{
      height: 60vh !important;
  }

  .roster-message-container{
    min-height: 83vh;
    position: relative;
  }

  .receive-message{
    flex: 1 1 auto; overflow-y: auto;
    border: 1px solid rgba(0, 0, 0, 0.125); 
    background-color: #fff;
    margin-bottom: 0 !important;
  }

  .slash-icon:after {
    position: absolute;
    content: "";
    left: 24px;
    top: -1px;
    height: 28px;
    width: 4px;
    background: #e21515;
    transform: rotate(-61deg);
  }

  .microphone-slash-icon:after {
    position: absolute;
    content: "";
    left: 24px;
    top: 0px;
    height: 28px;
    width: 4px;
    background: #e21515;
    transform: rotate(-61deg);
  }

  .speaker-slash-icon:after {
    position: absolute;
    content: "";
    left: 32px;
    top: -1px;
    height: 28px;
    width: 4px;
    background: #e21515;
    transform: rotate(-61deg);
  }

  .share-content-slash-icon:after {
    position: absolute;
    content: "";
    left: 47px;
    top: -1px;
    height: 28px;
    width: 4px;
    background: #e21515;
    transform: rotate(-61deg);
  }

  .attendee-font{
      font-size: 12px;
      height: 50px;
  }

  .attendee-name {
    /* flex: 2 1 75%; */
    max-width: 230px;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }

  .attendee-action-dropdown {
    position: relative;
    text-align: center;
    left:10px;
    width: 20px;
  }

  .attendee-action-dropdown-content {
    display: none;
 }
 .attendee-action-dropdown:hover .attendee-action-dropdown-content {
    display: block;
    right: 0;
    border: 1px solid lightgray; 
    position: absolute; 
    top: 10px;
    background-color: #FFF;
    z-index: 9999;
    width:160px;
 }
 .attendee-action-item{
    padding: 6px;
    text-align: left;
    cursor: pointer;
 }
 .attendee-action-item:hover{
    background-color: lightgray;
 }

 .conference-details-container{
    border:1px solid lightgray; 
    border-radius: 10px;
 }

 .leave-meeting-action-button {
    background-color: red; 
    color:white!important
 }

 .leave-meeting-action-button:hover {
    background-color: red; 
    color:white!important
 }

 .volume-indicator i {
     background-color: grey;
     display: inline-block;
     width: 4px;
     height: 0.3em;
     border-radius: 1em;
     transition: all 0.5s;
 }

 .volume-indicator.active i {
    background-color: green;
 }

 .volume-indicator.inactive i {
    background-color: yellow;
 }

 .volume-indicator.active > :first-child {
    height: 0.6em;
 }

 .volume-indicator.active > :nth-child(2) {
    height: 1em;
    position: relative;
    top: 0.2em;
 }

 .volume-indicator.active > :last-child {
    height: 0.6em;
 }


 .participant-item {
    position: relative;
  }
  
  .participant-overlay {
    position: absolute;
    bottom: 0;
    left: 100%;
    right: 0;
    /* background-color: #008CBA; */
    background-color: #e7ebee;
    overflow: hidden;
    width: 0;
    height: 100%;
    transition: .5s ease;
  }
  
  .participant-item:hover .participant-overlay {
    width: 100%;
    left: 0;
  }
  
  .participant-actions {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    white-space: nowrap;
  }

  .meeting-room-title {
    background-color: #e7ebee;
    margin: 0px;
    padding: 0.6em; 
  }