.review-reports-filter-container {
    position: relative;
    top: 62px;
    z-index: 1;
}

#reportsTable_paginate .pagination li {
    padding: 0px !important;
}

#reportsTable_paginate .pagination li:hover{
    background-color: none !important;
    background: none !important;
    border: 1px solid transparent !important;
}