.project-img-owner {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    display: block;
    /*float: left;*/
    height: 33px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 33px;
}

.filter-symbol-div-margin {
    margin: 10px;
}

.clearfix .popover {
    max-width: none;
    white-space: nowrap;
}

#filterSearchRequisitions {
    margin-top: 88px;
}

@media (max-width: 1431px) {
    #reportrange {
        background: #fff !important;
        cursor: pointer !important;
        padding: 7px 10px !important;
        border: 1px solid #ccc !important;
        width: 100% !important;
        height: 34px !important;
        display: block !important;
        font-size: 12px !important;
    }
}

.req-num {
    color: white;
    padding-left: 15px;
}

.req-date {
    color: white;
    padding-left: 5px;
}

.project-box .project-box-header .name a {
    padding: 0px 15px;
}

.project-box .project-box-content {
    padding: 6px 20px 5px;
    text-align: center;
}

.project-box .project-box-footer a {
    border-right: 1px solid #eee;
    color: #212121;
    display: block;
    float: left;
    margin: 0px 0 !important;
    padding: 0px 10px !important;
    text-align: center;
    width: 33.33%;
}

.dropdown-menu-action {
    min-width: 0px !important;
}

.status {
    color: #212121 !important;
    border: none !important;
    background: transparent;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 28px !important;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    width: 100%;
    text-align: left !important;
    padding: 1px 28px !important;
}

.action-drop-disabled {
    opacity: .45 !important;
}

.dropdown-menu>li>a {
    font-size: 14px !important;
}

.updateReqtextcolor {
    color: #03a9f4;
}

.custom-modal-header {
    padding: 15px !important;
    border-bottom: 1px solid #e5e5e5;
}

.modal-body-font-size {
    font-size: 14px;
}

.multiSelect>button {
    width: 100% !important;
    text-align: left !important;
}

.dropdown-menu-form {
    width: 100%;
}

.daterangeelement {
    height: 38px;
    font-size: 13px;
    padding-left: 10px;
    color: #555;
}

.inputBoxBorder {
    border: 1px solid #ccc;
}

.form-control {
    border-radius: 3px;
    background-clip: padding-box;
    border-color: #e7ebee;
    border-width: 2px;
    box-shadow: none;
    font-size: 14px;
}

.dropdown-width {
    width: 100%;
}

.activity-list {
    width: 400px !important;
}

/* card styles */
.requisition-card-title {
    padding: 6px;
    min-height: 68px;
}

.requisition-card-header {
    width: 100%;
}

.requisition-card-header:hover,
.requisition-card-header:visited,
.requisition-card-header:link,
.requisition-card-header:active {
    text-decoration: none;
}

.requisition-card-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 45%;
}

.requisition-card-icons-container {
    position: relative;
    top: -8px;
    font-size: 0.9em;
}

.requisition-card-openings-container {
    padding: 4px 0px 4px 0px;
}

.requisition-card-openings-icons-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding-left: 2.3em;
    padding-right: 2.3em;
}

.requisition-card-openings-icon {
    font-size: 18px;
}

.requisition-card-openings-icon i {
    font-size: 15px;
}

.requistion-card-notification {
    display: block;
    font-size: 1.4em;
    font-weight: 500;
    padding-top: 10px;
    border-radius: 3px 3px 0 0;
    background-clip: padding-box;
    transition: background-color 0.1s ease-in-out 0s;
}

.requistion-card-notification-icon>a>i {
    color: #fff !important;
}

.requistion-card-notification-icon>a.btn {
    border-bottom: 0px;
}

.requistion-card-notification-icon>a>span.count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    color: #fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 0px;
    width: 14px;
}

.requisition-card-loader-div {
    height: 100%;
    padding-top: 108px;
    padding-bottom: 108px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    background-clip: padding-box;
    border: 1px solid #e7ebee;
}

.requisition-card-client-or-bu-details {
    padding-top: 5px;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    width: 70%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.requisition-card-item {
    flex: 1;
    overflow: hidden;
}

.requisition-card-hiring-manager-count {
    position: absolute;
    right: 20%;
    border-radius: 100px;
    padding: 4px 5px 2px 5px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    margin-right: 25px;
    margin-top: -60px;
}

span.requisition-card-hiring-manager-count {
    background: #03a9f4;
    color: #fff;
    opacity: .8;
}

.requisition-card-shared-hiring-manager-count {
    position: absolute;
    right: 20%;
    border-radius: 100px;
    padding: 4px 5px 2px 5px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    margin-right: 25px;
    margin-top: -60px;
}

span.requisition-card-shared-hiring-manager-count {
    background: mediumpurple;
    color: #fff;
    opacity: .8;
}

.requisition-card-recruiter-count {
    position: absolute;
    right: 3px;
    border-radius: 100px;
    padding: 4px 5px 2px 5px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    margin-right: 25px;
    margin-top: -60px;
}

span.requisition-card-recruiter-count {
    background: #03a9f4;
    color: #fff;
    opacity: .8;
}

.internal-requisition-card-bg {
    background-color: mediumvioletred !important;
}

.internal-requisition-card-openings {
    color: mediumvioletred !important;
}

.shared-requisition-card-bg {
    background-color: mediumpurple;
}

.shared-requisition-color {
    color: mediumpurple !important;
}

.requisition-card-recruiters-indicator {
    float: left;
}


.requisition-card-recruiter-img-other {
    border: 3px solid mediumpurple;
}

.recruiter-item {
    flex-grow: 1;
    /* border: 1px solid lightgrey */
}

.shared-requisition-card-recruiter-count {
    position: absolute;
    right: 3px;
    border-radius: 100px;
    padding: 4px 5px 2px 5px;
    text-align: center;
    font-weight: normal;
    font-size: 13px;
    line-height: 1;
    margin-right: 25px;
    margin-top: -60px;
}

span.shared-requisition-card-recruiter-count {
    background: mediumpurple;
    color: #fff;
    opacity: .8;
}

.internal-requisition-card-button {
    background-color: mediumvioletred !important;
    border-color: #be7ba5 !important;
}

.shared-requisition-card-button {
    background-color: mediumpurple !important;
    border-color: #8361ca !important;
    color: #fff;
}

.shared-requisition-card-button:hover,
.shared-requisition-card-button:focus,
.shared-requisition-card-button:active,
.shared-requisition-card-button.active,
.open .dropdown-toggle.shared-requisition-card-button {
    background-color: #7c4dde !important;
    border-color: #1c5c87 !important;
    color: #fff;
}

.requisition-card-hiring-managers-popover {
    max-width: 500px;
    width: 300px;
    font-size: 1em;
}

.requisition-card-hiring-managers-popover-content-container {
    display: flex;
    flex-wrap: wrap;
}

.requisition-card-hiring-manager-img {
    border-radius: 50%;
    background-clip: padding-box;
    height: 25px;
    overflow: hidden;
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
}

.requisition-card-hiring-manager-name {
    font-size: 10px;
    padding: 5px 0px 5px 0px;
    line-height: 1;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #000000;
}

.requisition-card-bu-name {
    font-size: 0.8em;
}

.requisition-card-ultrafooter {
    height: 65px;
}

.requisition-card-ultrafooter-extra-padding-top-company-name {
    padding-top: 20px;
}

.requisition-card-ultrafooter-extra-padding-top-actions {
    padding-top: 10px;
}

@media screen and (max-width: 1460px) {
    .requisition-name {
        font-size: 12px;
    }
}

@media screen and (max-width: 1330px) {
    .requisition-card-name {
        max-width: 40%;
    }
}

@media screen and (max-width: 1450px) {
    .requisition-card-recruiters-indicator {
        float: left;
        font-size: 10px;
    }
}


.shared-req-icon {
    font-size: 14px;
    position: relative;
    bottom: 2px;
}

.status-danger {
    background-color: #e84e40 !important;
    color: #fff !important;
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
}

.requisition-card-contract-duration {
    font-size: 12px;
    position: absolute;
    top: 22px;
    left: 0px;
    padding-left: 8px;
}



.popover {
    max-width: 600px;
}

.requisition-card-specific-date {
    font-size: 12px;
    position: relative;
    font-weight: 600;
    left: 5px;
    top: 4px;
}

.open>.dropdown-menu {
    margin-bottom: 100px;
}

.action-separator {
    border-top: 1px solid lightgray;
}

.requisition-primary-hiring-manager-icon {
    position: absolute;
    right: 2px;
    bottom: 5px;
    font-size: 14px;
    font-family: cursive;
}

.requisition-end-client {
    font-size: 14px;
    color: white;
    cursor: default;
}
/* Row view */
.requisition-card-name-row-view {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: default;
    color: white;
}

.requisition-row-view-primary-hiring-manager-icon {
    position: relative;
    top: -3px;
    font-size: 14px;
    font-family: cursive;
}

.requisition-card-recruiter-heading {
    font-size: 1.3em;
    line-height: 29px;
    margin: 0;
    padding: 0;
}

.text-overflow-ellipsis-second-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.requisition-card-row-view-container {
    font-size: 13px;
    font-weight: 500;
    margin: 2px 8px;
    color: darkslategray;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    /* min-height: 220px; */
    max-height: 350px;
    border-radius: 5px;
    background-color: white;
}

.requisition-card-row-view-heading {
    height: 70px;
    border-bottom: 1px solid lightgray;
    color: white;
}

.requisition-card-row-view-shared {
    color: white;
    background-color: rgba(147, 112, 216, 0.84);
}

.requisition-card-row-view-internal {
    color: white;
    background-color: #03a9f4;
}

.requisition-card-row-view-content {
    /* min-height: 125px; */
    min-height: 90px;
    max-height: 250px;
}

.requisition-card-row-view-footer {
    font-size: 11px;
    height: 25px;
}

.requisition-name-row-view {
    font-size: 18px;
}

.candidate-name-row-view-status {
    font-size: 11px;
}

.requisition-card-row-view-items {
    border: 0.5px solid #00000030;
    border-radius: 15px;
    padding: 4px 6px;
    margin: 0px 5px 5px 0px;
}

.requisition-card-row-view-content-heading {
    font-size: 13px;
    font-weight: 600;
    color: gray;
}

.requisition-card-row-view-recruiter {
    min-width: 10px;
}

.requisition-row-view-brick-heading {
    font-weight: bold;
    color: gray;
}

.requisition-card-row-view-header-items {
    border: 0.5px solid white; 
    border-radius: 15px;
}

.re-run-score {
    color: red;
}