.add-candidate-alert{
    margin-bottom: 5px;
    margin-left: 25px;
    margin-right: 25px;
    margin-top: 15px;
    border-radius: 5px;
    padding: 5px;
    padding-left: 10px;
}

.add-candidate-form-container{
    padding-left: 40px;
    padding-right: 40px;
}

.add-candidate-loading-spinner {
    height: 251px;
    padding-top: 83px;
}