
/*	General CSS resets;
 *		The target itself is not affected, allowing
 *		the remainder of the document to use an
 *		alternate box-sizing model;
 *		Support for box-sizing is wide spread:
 *		http://caniuse.com/#search=box-sizing
 */
.noUi-target * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -ms-touch-action: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: default;
}

.slider-label {
    padding-top: 8px;
    display: block;
}

/*	Main slider bar;
 *		Standard styles no longer incorrectly force a
 *		width or height on the slider.
 */
.noUi-base {
    width: 100%;
    height: 8px;
    position: relative;
    max-width: 100%;
    max-height: 100%;
    /* border: 1px solid #DDDDDD; */
    z-index: 1;
}

/*	Handles + active state;
 *		The :after pseudo-element wont inherit
 *		box-sizing, so it needs to applied manually.
 */
.noUi-handle {
    background: #fff;
    border: 1px solid #DDDDDD;
    border-radius: 2px;
    height: 16px;
    margin: -4px 0 0 -9px;
    width: 16px;
    cursor: pointer;
}
.noUi-active {
    background: #E9E9E9;
}
.noUi-active:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    content: "";
    display: block;
    height: 100%;
    border: 1px solid #DDD;
}

/*	Styling-only classes;
 *		Structured to prevent double declarations
 *		for various states of the slider.
 */
.noUi-connect {
    background: #3498db;
}
.noUi-background {
    background: #eee;
}

.slider-danger .noUi-connect {
    background: #fe635f;
}
.slider-success .noUi-connect {
    background: #8dc859;
}
.slider-warning .noUi-connect {
    background: #f1c40f;
}
.slider-info .noUi-connect {
    background: #8F44AD;
}

/*	Functional styles for handle positioning;
 *		Note that the origins have z-index 0, the base has
 *		z-index 1; This fixes a bug where borders become invisible.
 */
.noUi-origin {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 0;
    border-radius: inherit;
}
/*.noUi-origin-upper {
    background: inherit;
}*/
.noUi-z-index {
    z-index: 0;
}

/*	Adaptations for the vertical slider;
 *		Some standard styles have been extended to keep
 *		exceptions for the vertical slider as minimal as possible.
 */
.noUi-vertical {
    width: 8px;
    height: 100%;
}
.noUi-vertical .noUi-origin {
    bottom: 0;
    left: 0;
}
.noUi-vertical .noUi-handle  {
    margin: -9px 0 0 -4px;
}

/*	Various alternate slider states;
 *		Support for transition is widely available,
 *		Only IE7, IE8 and IE9 will ignore these rules.
 *		Since this is merely a progressive enhancement,
 *		this is no problem at all.
 *		http://caniuse.com/#search=transition
 */
.noUi-target[disabled] .noUi-base {
    /*background: #999;*/
    background-color: green;
}
.noUi-target[disabled] .noUi-connect {
    /*background: #BBB;*/
    background-color: red;
}
.noUi-target[disabled] .noUi-origin-upper {
    /*background: #BBB;*/
    background-color: red;
}

.noUi-target[disabled] .noUi-origin-lower {
    /*background: #BBB;*/
    background-color: #FF8C00;
}

.noUi-target[disabled] .noUi-background {
    /*background: #BBB;*/
    background-color: green;
}

.noUi-state-tap .noUi-origin {
    -webkit-transition: left 0.3s, top 0.3s;
    transition: left 0.3s, top 0.3s;
}
