.user-notification-unread {
    background: #cfe8f2;
}

.user-notification-none-found {
    padding: 44px;
    font-size: 18px;
    color: gray;
}

.user-notification-row {
    background-color: #ffffff;
    padding-top: 8px;
    padding-bottom: 8px;
    border-top: 1px solid #f6f6f6;
}

.user-notification-unread-icon {
    font-size: 0.8em;
    padding-right: 5px;
}

.user-notification-selected-row {
    background-color: #f0efef !important;
}

.user-notification-candidates-icon {
    position: absolute;
    top: 3px;
    right: 30px;
}

.user-notification-workflow-icon {
    position: absolute;
    top: 3px;
    right: 6px;
}

.width-70 {
    width: 70%;
}

.width-80 {
    width: 80%;
}

.user-notifications-modal-large > .modal-dialog{
    width: 75%;
}
.user-notifications-modal-large > .modal-dialog > .modal-content{
    margin-top: 1%;
    min-height: 470px;
}