.associate-candidate-card-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50%;
    font-size: 16px;
}

.associate-candidate-title {
    margin-bottom: 5px;
    display: inline-block
}

.job-match-score-slider-highlight-score {
    font-weight: bold;
}

.associate-candidate-selected-card-bg {
    background-color: #e8a540 !important;
}


.associate-candidate-card-active-bg {
    background-color: #03a6f4 !important;
}

.associate-candidate-card-passive-bg {
    background-color: #00ccff !important;
}

.associate-candidate-card-inert-bg {
    background-color: #808080 !important;
}

.profile-box-menu .associate-candidate-card-header {
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
}

.associate-candidate-card-name-div {
    font-size: 1.3em;
    width: 90%;
}

.associate-candidate-card-last-modified-date-div {
    padding-top: 5px;
}

.associate-candidate-card-phone {
    font-size: 0.75em;
}

.associate-candidate-card-email {
    font-size: 0.75em;
}

.associate-candidate-card-last-modified-date {
    font-size: 0.8em;
}

.associate-candidate-card-email a {
    color: #fff;
    text-decoration: none !important;
}

.associate-candidate-card-email a:hover {
    color: #fff;
    text-decoration: none !important;
}

.associate-candidate-card-email a:visited {
    color: #fff;
    text-decoration: none !important;
}

.associate-candidate-card-popovers-container .popover-content {
    white-space: nowrap !important;
}

.associate-candidate-card-job-matches-popover {
    max-width: 700px;
    width: 600px;
    max-height: 200px;
    overflow-y: scroll;
    font-size: 0.6em;
}

.associate-candidate-job-matches-popover {
    width: 600px;
    font-size: 0.6em;
    z-index:1;
}

.associate-candidate-card-match-score-na {
    font-size: 1.2em;
    display: block;
    margin-top: 0.2em;
}

.associate-candidate-card-match-score {
    font-size: 1.2em;
    display: block;
    font-weight: 900;
    margin-left: 1em;
    margin-right: 1em;
    margin-top: 0.2em;
    color: #fff;
}

.associate-candidate-card-match-score-success {
    border-radius: 5px;
}

.associate-candidate-card-match-score-failure {
    background: darkorange;
    border-radius: 5px;
}

.associate-candidate-card-job-matches-icon {
    margin-top: 0.1em;
    margin-bottom: 0em;
    font-size: 1.5em;
}

.associate-candidate-card-job-matches-icon a {
    color: #212121;
    background-color: white !important;
    text-decoration: none !important;
}

.associate-candidate-card-job-matches-icon a:hover {
    color: #212121;
    background-color: white !important;
    text-decoration: none !important;
}

.associate-candidate-card-job-matches-icon a:visited {
    color: #212121;
    background-color: white !important;
    text-decoration: none !important;
}

.associate-candidate-card-actions {
    margin-top: 0.03em;
    font-size: 1.5em;
}

.associate-candidate-warning-message {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid;
}

.associate-candidate-modal > .modal-dialog{
    width: 85%;
}
.associate-candidate-modal > .modal-dialog > .modal-content{
    margin-top: 1%;
}

.associate-cards-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-gap: 1em;
}

@media screen and (min-width: 0px) {
    .associate-cards-container {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        -ms-grid-columns: 1fr;
    }
}

@media screen and (min-width: 800px) {
    .associate-cards-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        -ms-grid-columns: 1fr 1fr;
    }
}

@media screen and (min-width:1400px) {
    .associate-cards-container {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        -ms-grid-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 2000px) {
    .associate-cards-container {
        grid-template-columns: repeat(4, minmax(0, 1fr));
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
    }
}

.associate-candidate-card-job-matches-popover-count {
    width: 1em;
    font-size: 21px;
    position: relative;
    top: 2.5px;
}