@CHARSET "ISO-8859-1";

.candidate-job-match-card-container {
    width: 98%;
    margin-bottom: 10px;
    height: 208px;
}

.multiSelect > button {
    width: 100% !important;
    text-align: left !important;
}

.candidate-job-match-card-container .menu-items li a{
    height: 30px;
    line-height: 30px;
}

.candidate-job-match-card-loader-div {
    height: 100%;
    padding-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    /* overflow: hidden; */
    border-radius: 3px;
    background-clip: padding-box;
    border: 1px solid #e7ebee;
}


.job-match-card-dark-green-bg {
    background-color: #4e9a06 !important;
}

.job-match-card-green-bg {
    background-color: #8bc34a !important;
}

.job-match-card-dark-blue-bg {
    background-color: #03a6f4 !important;
}

.job-match-card-blue-bg {
    background-color: #00ccff !important;
}

.job-match-card-grey-bg {
    background-color: #808080 !important;
}

.candidate-job-match-card-notifications-list{
    width: 250px;
    height: 200px;
    overflow-y: scroll;
}

.candidate-job-card-notification-icon a, .candidate-job-card-notification-icon a:hover, .candidate-job-card-notification-icon a:visited {
    border-bottom: 0px;
    color: white;
}

.candidate-job-card-notification-icon .btn {
    border-bottom: none;
    padding-left: 3px;
    padding-right: 8px;
}

.candidate-job-card-notification-icon-left a, .candidate-job-card-notification-icon-left a:hover, .candidate-job-card-notification-icon-left a:visited {
    border-bottom: 0px;
    color: white;
    margin-left:5px;
}

.candidate-job-card-notification-icon-left .btn {
    border-bottom: none;
    padding-left: 8px;
    padding-right: 3px;
}

.candidate-job-match-card-notification-text{
    color: #333;
}

.candidate-job-match-card-notification-unread{
    background: #cfe8f2;
}

#candidatesTitle {
    background-color: #f9f9f9;
}

#filterSearchCandidates {
    margin-top: 88px;
}

#checkscale {
    position: relative;
    top: -4px
}

#checkcircle {
    position: relative;
    top: -4px;
    margin-left: 7px;
}

.profile-box-notification {
    height: 30px;
}

.profile-box-menu .candidate-job-match-card-header {
    margin-top: 1px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
}

.candidate-job-match-card-name-div {
    margin-bottom: 5px;
    font-size: 1em;
}

.candidate-job-match-card-detail-div {
    font-size: 1em;
}

.candidate-job-match-card-detail-div a {
    color: #fff;
}

.candidate-job-match-card-detail-div a:hover {
    color: #fff;
}

.candidate-job-match-card-detail-div a:visited {
    color: #fff;
}

.candidate-job-match-card-detail-div a:focus {
    color: #fff;
}

.candidate-job-match-card-candidate-name-container{
    font-weight: 600;
    font-size: 1.2em;
}

.candidate-job-match-card-wrap-candidate-name{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    max-width: 30%;
}

.candidate-job-match-card-vendor-name{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60%;
}

.candidate-job-match-card-update-contact-icon{
    display: inline-block;
    overflow: hidden;
    font-size: 1.2em;
}

.candidate-job-match-card-value-assessment-icon{
    display: inline-block;
    overflow: hidden;
    font-size: 1.2em;
}

.candidate-job-match-card-manual-match-icon{
    display: inline-block;
    position: relative;
    bottom: 5px;
    font-size: 15px;
}

.candidate-job-match-card-img {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    width: 45px;
    height: 45px;
    border: 1.5px solid #fff;
    margin-right: 10px;
}

.candidate-job-match-card-recruiter-img {
    border-radius: 50%;
    background-clip: padding-box;
    height: 25px;
    overflow: hidden;
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
}

.notification-count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    color: #fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 2px;
    width: 14px;
}
.unread-message-count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    color: #fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    right: 103px;
    text-align: center;
    top: 2px;
    width: 14px;
}

.candidate-job-match-card-recruiter-count {
    background: #03a9f4;
    color: #fff;
    opacity: .8;
    position: absolute;
    right: -20%;
    border-radius: 100px;
    padding: 3px 3px 3px 3px;
    text-align: center;
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    margin-right: 40px;
    margin-top: -35px;
}

.candidate-job-match-card-recruiter-name {
    font-size: 10px;
    padding: 5px 0px 5px 0px;
    line-height: 1;
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: #000000;
}

.candidate-job-match-card-popovers-container .popover-content {
    white-space: nowrap !important;
}

.candidate-job-match-card-popover-recruiter {
    float: left;
    display: inline-block !important;
}

.no_candidates_found {
    height: 187px;
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
}

.candidate-job-match-card-footer-section {
    margin-top: 15px;
}

.candidate-job-match-row {
    margin-top: 2px;
    border-top: 1px dotted;
}

.candidate-job-matches-popover {
    max-width: 700px;
    width: 550px;
    font-size: 1em;
}

.candidate-job-match-card-recruiters-popover-content-container{
    display: flex; 
    flex-wrap: wrap;
}

.candidate-job-match-card-recruiters-popover {
    max-width: 500px;
    width: 300px;
    font-size: 1em;
}


.candidate-job-match-card-tech-assessment-details-popover-content-container{
    display: flex; 
    flex-wrap: wrap;
}

.candidate-job-match-card-tech-assessment-details-popover {
    max-width: 275px;
    width: 275px;
    font-size: 1em;
}

.candidate-job-match-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.candidate-job-match-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875em;
    font-weight: 600;
}

.candidate-job-match-footer {
    padding-top: 10px;
}

.candidate-job-match-footer a, .candidate-job-match-footer a:hover, .candidate-job-match-footer a:visited {
    color: black;
}

.inert-candidate-current-job-details{
    padding: 5px;
    border-radius: 5px;
    font-size: 0.95em;
    background-color: #949494;
    height: 85px;
}

.job-match-card-disabled{
    opacity: 0.45;
    pointer-events: none;
}

.job-match-card-dirty-icon{
    background: red; 
    pointer-events: all; 
    height: 95%;
    opacity: 1;
}

.candidate-job-match-card-tech-score-success{
    /*background: green;*/
    color: #fff;
    border-radius: 5px;
    padding: 4px;
}

.candidate-job-match-card-tech-score-failure{
    background: darkorange;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
}

.candidate-job-match-card-intelligence-score-success{
    /*background: green;*/
    color: #fff;
    border-radius: 5px;
    padding: 4px;
}

.candidate-job-match-card-intelligence-score-failure{
    background: darkorange;
    color: #fff;
    border-radius: 5px;
    padding: 4px;
}

.candidate-job-match-card-only-intelligence-score-success{
    /*background: green;*/
    color: #fff;
    border-radius: 5px;
}

.candidate-job-match-card-only-intelligence-score-failure{
    background: darkorange;
    color: #fff;
    border-radius: 5px;
}

.candidate-job-match-card-report-label{
    padding: 15px;
    padding-top: 10px;
    margin-bottom: 20px;
}

.candidate-job-match-card-meeting-count-container{
    color: #fff;
    opacity: 0.9;
    position: absolute;
    text-align: center;
    font-weight: normal;
    font-size: 0.85em;
    margin-left: 0.3em;
    margin-top: -0.4em;
}

.candidate-job-match-card-meeting-count{
    margin-top: 0.1em;
}

.candidate-job-match-card-assessment-info{
    margin-top: 0.4em;
    font-size: 1.5em;
}

.candidate-job-match-card-meetings-popover {
    max-width: 850px;
    width: 700px;
    font-size: 1em;
}

.candidate-job-match-card-assessment-report-modal-window > .modal-dialog{
    width: 1100px !important;
}

.candidate-job-match-activity-list{
    width: 330px !important;
    left: -290px !important;
}

.candidate-activities-modal-window > .modal-dialog{
    width: 1100px !important;
    top: -120px;
}

.candidate-job-match-card-modal-large > .modal-dialog{
    width: 80%;
}

.job-match-card-filled-joined-bg{
    background-color: #89c441 !important;
}

.job-match-card-selected-bg{
    background-color: #e8a540 !important;
}

.candidate-job-match-card-recruiter-img-other{
    border: 3px solid mediumpurple;
}

.candidate-job-match-card-other-recruiter{
    color: mediumpurple;
    font-weight: bold;
}

.candidate-job-match-card-filled-joined-requisition-popover{
    max-width: 350px;
    width: 300px;
    font-size: 1em;
}

.candidate-job-match-card-resume-upload-required{
    border-radius: 6px;
    background: #ff0000e0;
    width: 78%;
    margin-left: 7px !important;
}

.candidate-job-match-card-notes-icon {
    margin-left: 5px;
    color:#f5f5f5;
    /* color:#add8e66e; */
    margin-top: 2px;
    display: inline-block;
}

.candidate-job-match-card-assessment-violation-icon {
    position: relative;
    top: -37px;
    right: -55px;
}

.candidate-job-match-card-assessment-main-violation-icon {
    top: -10px;
    left: 4px;
}

.action-separator-job-match-card {
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    margin: 5px 0px;
}

.action-item {
    padding-top: 2px;
    padding-bottom: 2px;
}

.action-green {
    background-color: #89c441;
}

.action-green>a:hover {
    background-color: #95c956 !important;
}

.candidate-job-match-card-assigned-recruiter {
    font-size: 0.7em;
    position: absolute;
    top: 6px;
    right: 12px;
}

.candidate-job-card-re-run-icon {
    color: red;
    position: relative;
    top: 6px;
    padding-right: 7px;
}