
/* #addUserButton {
	background-color: #8bc34a !important;
	border-color: #8bc34a !important;
	color: white !important;
}

#actionButton {
	background-color: #8bc34a !important;
	border-color: #8bc34a !important;
	color: white !important;
}

 */
#addUserLink {
	color: white;
}

.dropdownOption {
	text-align: center !important;
	font-size: 0.875em !important;
	color: #000000;
}
.dropdownOption:hover{
	color: #03a9f4;
}

.dropdownButtonGroup {
	margin-top: 8px;
}

/* User view card */

.user-row-view-card {
	background-color: white;
	color: gray;
}

.user-row-view-card-header {
	background-color: #03a9f4;
	color: white;
}

.user-row-view-card-image {
	width: 30px;
	height: 30px;
	border-radius: 50%;

}

.user-row-view-card-designation {
	font-size: 12px;
	border-left: 0.5px solid lightgray;
}

.user-row-view-card-requisition {
	border-left: 0.5px solid lightgray;
}

.user-card-expand-icon-right, .user-card-expand-icon-left {
	font-size: 16px;
	cursor: pointer;
	background-color: #d3d3d3ad;
}

.width-transition {
    transition: width 0.5s ease-in-out;
}

.opacity-transition-300 {
	transition: opacity 0.3s ease-in-out;
}

.opacity-transition-500 {
	transition: opacity 0.5s ease-in-out;
}
