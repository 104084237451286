
/*	General CSS resets;
 *		The target itself is not affected, allowing
 *		the remainder of the document to use an
 *		alternate box-sizing model;
 *		Support for box-sizing is wide spread:
 *		http://caniuse.com/#search=box-sizing
 */
	.noUi-target * {
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
		box-sizing: border-box;
-webkit-touch-callout: none;
    -ms-touch-action: none;
-webkit-user-select: none;
   -moz-user-select: none;
    -ms-user-select: none;
		cursor: default;
	}

/*	Main slider bar;
 *		Standard styles no longer incorrectly force a
 *		width or height on the slider.
 */
	.noUi-base {
		width: 100%;
		height: 40px;
		position: relative;
		max-width: 100%;
		max-height: 100%;
		border: 1px solid #bfbfbf;
		z-index: 1;
	}

/*	Handles + active state;
 *		The :after pseudo-element wont inherit
 *		box-sizing, so it needs to applied manually.
 */
	.noUi-handle {
		background: #EEE;
		height: 44px;
		width: 44px;
		border: 1px solid #BFBFBF;
		margin: -3px 0 0 -23px;
	}
	.noUi-active {
		background: #E9E9E9;
	}
	.noUi-active:after {
-webkit-box-sizing: border-box;
   -moz-box-sizing: border-box;
		box-sizing: border-box;
		content: "";
		display: block;
		height: 100%;
		border: 1px solid #DDD;
	}

/*	Styling-only classes;
 *		Structured to prevent double declarations
 *		for various states of the slider.
 */
	.noUi-connect {
		background: Teal;
	}
	.noUi-background {
		background: #DDD;
	}

/*	Functional styles for handle positioning;
 *		Note that the origins have z-index 0, the base has
 *		z-index 1; This fixes a bug where borders become invisible.
 */
	.noUi-origin {
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		z-index: 0;
		border-radius: inherit;
	}
	.noUi-origin-upper {
		background: inherit !important;
	}
	.noUi-z-index {
		z-index: 10;
	}

/*	Adaptations for the vertical slider;
 *		Some standard styles have been extended to keep
 *		exceptions for the vertical slider as minimal as possible.
 */
	.noUi-vertical {
		width: 40px;
		height: 100%;
	}
	.noUi-vertical .noUi-origin {
		bottom: 0;
		left: 0;
	}
	.noUi-vertical .noUi-handle  {
		margin: -23px 0 0 -3px;
	}

/*	Various alternate slider states;
 *		Support for transition is widely available,
 *		Only IE7, IE8 and IE9 will ignore these rules.
 *		Since this is merely a progressive enhancement,
 *		this is no problem at all.
 *		http://caniuse.com/#search=transition
 */
	.noUi-target[disabled] .noUi-base {
		background: #999;
	}
	.noUi-target[disabled] .noUi-connect {
		background: #BBB;
	}
	.noUi-state-tap .noUi-origin {
		-webkit-transition: left 0.3s, top 0.3s;
		transition: left 0.3s, top 0.3s;
	}
