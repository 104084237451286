.meeting-icon-in-progress{
    color: #e88e12;
}

.meeting-icon-scheduled{
    color: #2153e8;
}

.meeting-icon-overdue{
    color: #e85e1d;
}

.meeting-icon-cancelled{
    color: #ff182c;
}

.meeting-icon-completed{
    color: #006416;
}

.disabled-meeting-label{
    color: #828181;
}

tags-input.has-tags input {
    display: none;
}

.schedule-job-match-meeting-browser-time-zone-info{
    background-color: #f5f5dc;
    padding: 2px 2px 2px 0px;
    border-radius: 5px;
}

.schedule-job-match-meeting-user-name{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.schedule-job-match-meeting-meeting-label{
    width: 100% !important;
}

input.input{
    min-width: 400px;
}

.schedule-job-match-meeting-form-error-color{
    color: #e84e40 !important;
}

.schedule-job-match-meeting-form-error{
    border: 2px solid #e84e40 !important;
}

.close-tab {
    position: relative;
    top: -10px;
    left: 6px;
    font-size: 13px;
}

.schedule-job-match-meeting-modal-medium > .modal-dialog{
    width: 65%;
}
.schedule-job-match-meeting-modal-medium > .modal-dialog > .modal-content{
    margin-top: 1%;
}