
.folder:hover, .folder-active {
    background-color: rgba(147, 197, 253,0.3);
    color:black !important;
}

.folders-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    height: 500px;
    max-height: 500px;
    overflow-y: auto;
    align-content: flex-start;
}

.folder-document {
    -ms-flex: 31%; /* IE10 */
  flex: 31%;
  max-width: 31%;
  padding: 0 4px;
  margin-right:10px;
  margin-top:10px;
  max-height: 176px;
}

@media screen and (max-width: 1330px){
    .folder-document {
        -ms-flex: 45%; /* IE10 */
      flex: 45%;
      max-width: 45%;
      padding: 0 4px;
      margin-right:10px;
    }
} 

@media screen and (max-width: 950px){
    .folder-document {
        -ms-flex: 80%; /* IE10 */
      flex: 80%;
      max-width: 80%;
      padding: 0 4px;
      margin-right:10px;
    }
} 

.folder-icon {
    height:19px;
}

.folder-document:hover {
    background-color: rgba(147,197,253,.3);
}

.document-actions .document-action {
    height: 30px;
    line-height: 30px; 
    margin-left: 5px; 
    padding-left: 5px;
}

.candidate-documents-modal > .modal-dialog{
    width: 85%;
}
.candidate-documents-modal > .modal-dialog > .modal-content{
    margin-top: 0%;
}

.candidate-document-selected-bg {
    background-color: #e8a54073!important;
}

.attachment-clip {
    font-size:17px; 
    position:relative; 
    transform: rotateZ(318deg);
}

.email-documents-multi-select .multiSelect .checkboxLayer {
    width: 150% !important;
}

.send-email-fields label{
    width: 40px;
}

.other-company-user-tag {
    background: linear-gradient(to bottom, #9370db47 0%, #9370db61 47%, #9370db87 100%) !important;
}

.other-company-auto-complete-user-tag {
    color: mediumpurple !important;
}

.other-company-auto-complete-user-tag-selected {
    background-color: mediumpurple !important;
    color: white !important;
}

.email-documents-resume-checkbox {
    padding-top: 2px;
}

.email-tags-input .tags .tag-item {
    cursor: pointer !important;
    height: fit-content !important;
    position: relative !important;
    padding: 0px 25px 0px 5px !important;
}

.email-tags-input .tags .tag-item .remove-button {
    position: absolute !important;
    right: 7px !important;
    top: 4px !important;
}

.new-email-user-tag {
    background: linear-gradient(to bottom, #cbff8cd9 0%, #adf159a8 47%, #89c441bd 100%) !important;
}