.candidate-profile-header{
    margin-bottom: 20px;
}

.candidate-profile-assets {
    font-size: 0.95em;
}

.candidate-profile-assets i {
    font-size: 1.5em;
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    text-align: center;
}

.candidate-profile-assets .asset-value {
    color: #03a9f4;
}

.candidate-profile-name{
    font-size: 1.5em;
}

.candidate-profile-edit-contact{
    font-size: 0.75em;
}

.candidate-profile-contact-error-icon{
    font-size: 0.75em;
}

.candidate-profile-download-resume-icon{
    font-size: 0.6em;
}

.candidate-profile-download-resume-icon a:hover{
    text-decoration: none;
}

.candidate-profile-download-resume-icon a:focus{
    text-decoration: none;
}

.candidate-profile-email{
    font-size: 1.1em;
}

.candidate-profile-url{
    font-size: 1.1em;
}

.candidate-profile-phone{
    font-size: 1.1em;
}

.candidate-profile-address{
    font-size: 1em;
}

.candidate-profile-modal > .modal-dialog{
    width: 75%;
}
.candidate-profile-modal > .modal-dialog > .modal-content{
    margin-top: 0%;
}