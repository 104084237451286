.workflow-refresh-message {
    position: fixed;
    z-index: 10001;
    background: darkslategrey;
    color: white;
    padding: 20px;
    bottom: 1em;
    left: 5em;
}

.workflow-refresh-message a {
    text-decoration: none;
}

.workflow-refresh-step-change-count {
    color: yellow;
}

.no-workflow-cards-message{
    position: absolute;
    top: 3em;
    left: 7em;
}

.candidate-state-wrapper ul[dnd-list]{
    min-height: calc(100vh - 280px);
}

.candidate-state-wrapper-list .dndPlaceholder {
    height: 100%;
    padding-top: 200px;
    background-color: lightgrey;
    /* background-color: lightgrey; */
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    border-radius: 3px;
    background-clip: padding-box;
    border: 1px solid #e7ebee;
}

.candidate-state-wrapper-list .dndDraggingSource {
    display: none;
}

.candidate-state-wrapper-list .dndDragging {
    cursor: grabbing !important;
}