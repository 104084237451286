/* cellpadding */
.feedback-requisitions-table * th, td {
	padding: 0.3em;
}

.scrollable-table {
	max-height: 220px;
	width: 100%;
	margin: 0;
	overflow-y: auto;
}

/* align (center) */
.feedback-question-table {
	margin: 0 auto;
}

.disable-element-view {
	color: #9e9e9e !important;
	opacity: 1;
	cursor: auto;
}

.feedback-meeting-icon-in-progress{
    color: #e88e12;
}

.feedback-meeting-icon-scheduled{
    color: #2153e8;
}

.feedback-meeting-icon-cancelled{
    color: #ff182c;
}

.feedback-meeting-icon-completed{
    color: #006416;
}

.feedback-meeting-icon-overdue{
	color: #e85e1d;
}

.feedback-disabled-meeting-label{
    color: #828181;
}

.zIndex1060{
	z-index: 1100;
}

.toggle-notes-button{
	height:22px; 
	position: relative; 
	top:-35px;
	left:5px;
}

.recorded-by{
	color: #5C5858; 
	font-size: 11px; 
	float:right;
	padding-top: 0.5rem;
}

.interested-job {
	background-color: #03a9f426;
}