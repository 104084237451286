.dz-progress{
    top: 70% !important;
}

.upload-resumes-associate-candidate-selection-bg{
    background-color: #e8a540 !important;
}

.upload-resumes-review-table-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875em;
    font-weight: 600;
}

.upload-resumes-review-table-row {
    margin-top: 0.2em;
    border-top: 1px dotted;
    padding: 1em;
}

.upload-resumes-review-table-header-row{
    padding: 0.2em 1em 0.2em 1em;
}

.upload-resumes-counts-row{
    margin-top: 20px;
    margin-bottom: 20px;
}

.upload-resumes-counts-heading{
    font-size: 1.2em;
    padding: 10px 0px 0px 40px;
}

.upload-resumes-candidates-modal-large > .modal-dialog{
    width: 80%;
}

.upload-resumes-modal-medium > .modal-dialog{
    width: 75%;
}
.upload-resumes-modal-medium > .modal-dialog > .modal-content{
    margin-top: 5%;
}

.upload-resumes-counts-sub-heading {
    font-size: 15px;
}

.non-processed-heading {
    font-size: 16px;
}

.non-processed-table-heading {
    font-size: 13px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
}

.non-processed-table-content {
    font-size: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.un-processed-download-icon {
    width: 2em;
    font-size: 8px;
    bottom: 9px;
    position: absolute;
} 