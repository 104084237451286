#nav-col {
    padding: 0;
    z-index: 1000;
    position: absolute;
    background: #2c3e50;
    width: 220px;
}

@media (max-width: 991px) {
    #nav-col {
        position: fixed;
        width: 100vw;
        height: 100vh;
        transition: display 450ms ease-in;
    }
}

@media (max-width: 991px) {
    .navbar-toggle {
        display: block;
    }
}