.job-match-meetings-statement{
    padding: 0px 15px 15px 15px;
    margin-bottom: 10px;
}

.job-match-meetings-browser-time-zone-column{
    padding: 0px 0px 0px 0px !important;
    border-top: 0px solid !important;
}

.job-match-meetings-browser-time-zone-info{
    background-color: #f5f5dc;
    margin:0px 10px 0px 10px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
}

.job-match-meetings-browser-time-zone-space{
    padding-bottom: 8px;
}

.job-match-meetings-info-with-browser-time-zone-column{
    padding-bottom: 4px !important;
}

.job-match-meetings-meeting-icon-in-progress{
    color: #e88e12;
}

.job-match-meetings-meeting-icon-scheduled{
    color: #2153e8;
}

.job-match-meetings-meeting-icon-cancelled{
    color: #ff182c;
}

.job-match-meetings-meeting-icon-completed{
    color: #006416;
}

.job-match-meetings-meeting-icon-overdue{
    color: #e85e1d;
}

.job-match-meetings-feedback-type-icon{
    font-size: 14px;
}

.job-match-meetings-modal-medium > .modal-dialog{
    width: 65%;
}
.job-match-meetings-modal-medium > .modal-dialog > .modal-content{
    margin-top: 1%;
}

.job-match-meetings-modal-large > .modal-dialog{
    width: 75%;
}
.job-match-meetings-modal-large > .modal-dialog > .modal-content{
    margin-top: 1%;
}