.add-candidates-from-job-boards-container {
    padding: 20px;
}

.job-boards-parameters-container {
    padding-top: 20px;
    margin-left: -16px;
}

.add-candidates-job-boards-pull-limit-warning-container {
    margin-bottom: 15px
}

.add-candidates-job-boards-pull-limit-warning {
    font-size: small;
    padding: 5px;
    border: 1px solid lightgrey;
}