.candidate-card-notifications-list{
    width: 250px;
    height: 200px;
    overflow-y: scroll;
}

.candidate-card-notification-icon a, .candidate-card-notification-icon a:hover, .candidate-card-notification-icon a:visited {
    border-bottom: 0px;
    color: white;
}

.candidate-card-notification-icon .btn {
    border-bottom: none;
    padding-left: 3px;
    padding-right: 8px;
}

.candidate-card-notification-icon-left a, .candidate-card-notification-icon-left a:hover, .candidate-card-notification-icon-left a:visited {
    border-bottom: 0px;
    color: white;
}

.candidate-card-notification-text{
    color: #333;
}

.candidate-card-notification-icon-left .btn {
    border-bottom: none;
    padding-left: 8px;
    padding-right: 3px;
}

.candidate-card-notification-unread, .candidate-card-notification-unread a:hover, .candidate-card-notification-unread a:visited {
    background: #cfe8f2;
}

.candidate-card-notification-date{
    display: inline-block;
    color: #797979;
    font-size: 0.8em;
}

.candidate-activity-list{
    left: -280px!important;
    width: 326px!important;
}

.candidate-card-unread-message-count {
    background: none repeat scroll 0 0 #e84e40;
    background-clip: border-box;
    border-radius: 50%;
    background-clip: padding-box;
    color:#fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    left: 13px;
    text-align: center;
    bottom: 16px;
    width: 14px;
}

.candidate-activities-modal-window > .modal-dialog{
    width: 1100px !important;
}

.candidate-card-wrap-candidate-name{
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 1.2em;
    max-width: 30%;
}

.candidate-card-update-contact-icon{
    display: inline-block;
    overflow: hidden;
    font-size: 1.2em;
    margin-left:5px;
}

.candidate-card-contact-detail-div{
    font-size: 1em;
    overflow: inherit;
    min-height: 30px;
}

.candidate-card-detail-left{
    width: 67%;
}

.candidate-card-detail-right{
    width: 33%;
}

.candidate-card-modal-large > .modal-dialog{
    width: 80%;
}

.candidate-card-job-matches-popover{
    width: 600px;
    font-size: 1em;
    z-index:10;
}

.candidate-card-active-bg {
    background-color: #03a6f4 !important;
}

.candidate-card-passive-bg {
    background-color: #00ccff !important;
}

.candidate-card-inert-bg {
    background-color: #808080 !important;
}

.candidate-card-filled-joined-bg {
    background-color: #89c441 !important;
}

.candidate-card-selected-bg{
    background-color: #e8a540 !important;
}

.candidate-card-availability-date {
    display: inline-block;
    position: relative;
    bottom: 5px;
    font-size: 15px;
} 

.candidate-card-job-match-disabled-row {
    background-color: lightgrey!important;
}

.candidate-card-internal-card{
    height: 247px;
}

.candidate-card-vendor-card {
    height: 267px;
}

.candidate-card-vendor-card, .candidate-card-vendor-card-row-view {
    box-shadow: 0px 0px 5px 2px mediumpurple !important;
}

.drop-down-action{
    border-top: 1px solid lightgray;
}

.action-danger {
    color: #fff !important;
    background-color: #e84e40;
}

.action-danger:hover{
    background-color: #e84e40 !important;

}

.candidate-card-manual-match {
    position: relative;
    bottom: 1px;
}


.candidate-card-row-view-img {
    border-radius: 50%;
    border: 1.5px solid #fff;
}

.candidate-card-manual-match-row-view {
    position: relative;
    bottom: 6px;
    font-size: 13px;
}

.candidate-card-recruiter-img-row-view {
    border-radius: 50%;
}

.candidate-card-recruiter-count-row-view {
    background: #03a9f4;
    color: #fff;
    opacity: .8;
    position: absolute;
    border-radius: 100px;
    padding: 3px 3px 3px 3px;
    text-align: center;
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    margin-left: 50px;
    top: -8px;
}

.candidate-card-job-matches-count-row-view {
    font-size: 13px;
    color: white !important;
}

.candidate-card-actions-row-vi3w {
    width: fit-content;
}

.candidate-card-actions-row-view li a {
    border-bottom: none !important;
}

/* Row View */
.candidate-card-row-view-container {
    font-size: 13px;
    font-weight: 500;
    margin: 2px 8px;
    color: darkslategray;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    min-height: 220px;
    max-height: 350px;
    border-radius: 5px;
    background-color: white;
}

.candidate-card-row-view-heading {
    height: 75px;
    border-bottom: 1px solid lightgray;
    color: white;
}

.candidate-card-row-view-content {
    min-height: 120px;
    max-height: 250px;
}

.candidate-card-row-view-footer {
    font-size: 12px;
    height: 25px;
}

.candidate-name-row-view {
    font-size: 15px;
}

.candidate-name-row-view-status {
    font-size: 11px;
}

.candidate-card-row-view-items {
    border: 0.5px solid #00000030;
    border-radius: 15px;
    padding: 4px 6px;
    margin: 0px 5px 5px 0px;
}

.candidate-card-row-view-content-heading {
    font-size: 13px;
    font-weight: 600;
    color: gray;
    text-transform: uppercase;
}

.candidate-card-row-view-recruiter {
    min-width: 10px;
    max-width: 250px;
}

.candidate-card-row-view-header-icon {
    font-size: 11px;
    border: 0.5px solid #d3d3d399;
    border-radius: 15px;
    padding: 4px 6px;
}

.candidate-card-row-view-active-bg-content {
    background-color: #03a6f421 !important;
}

.candidate-card-row-view-passive-bg-content {
    background-color: #00ccff1a !important;
}

.candidate-card-row-view-inert-bg-content {
    background-color: #8080802b !important;
}

.candidate-card-row-view-filled-joined-bg-content {
    background-color: #89c44138 !important;
}

.candidate-card-popovers-container {
    color: black;
}

.candidate-card-re-run-icon {
    color: red !important;
    position: relative;
    top: 6px;
    padding-right: 7px;
}