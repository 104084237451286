/* display utilities */
.d-inline {
    display: inline !important;
}

.d-block {
    display: block !important;
}

.d-inline-block {
    display: inline-block !important;
}

.d-flex {
    display: flex !important;
}


/* Alignment utilities */
.p-0 {
    padding: 0px !important;
}

.pb-0 {
    padding-bottom: 0px !important;
} 

/* bg colors */
.red-bg {
    background-color: red;
}

.orange-bg{
    background-color: orange;
}

.dark-orange-bg {
    background: darkorange;
}

.dark-green-bg {
    background-color: #4e9a06 !important;
}

.green-bg {
    background-color: #8bc34a !important;
}

.dark-blue-bg {
    background-color: #03a6f4 !important;
}

.blue-bg {
    background-color: #00ccff !important;
}

.grey-bg {
    background-color: #808080 !important;
}

/*Text colors */
.text-white {
    color: white !important;
}

/* border */
.border-orange{
    border: 2px solid orange;
}

.visibility-hidden{
    visibility: hidden !important;
}

/* position */
.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

.position-fixed {
    position: fixed !important;
}

.position-sticky {
    position: sticky !important;
}