@CHARSET "ISO-8859-1";

.candidate-card-container {
    width: 100%;
    margin-bottom: 10px;
    height: 240px;
}

.candidate-cards-container {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    -ms-grid-columns: 1fr;
    grid-gap: 1em;
}

@media screen and (min-width: 800px){
    .candidate-cards-container{
        grid-template-columns: repeat(2,minmax(0,1fr));
        -ms-grid-columns: 1fr 1fr;
    }
} 

@media screen and (min-width:1180px){
    .candidate-cards-container {
        grid-template-columns: repeat(3,minmax(0,1fr));
        -ms-grid-columns: 1fr 1fr 1fr;
    }
}

@media screen and (min-width: 1520px) {
    .candidate-cards-container {
        grid-template-columns: repeat(4,minmax(0,1fr));
        -ms-grid-columns: 1fr 1fr 1fr 1fr;
    }
 }

 @media screen and ( min-width : 1400px) and ( max-width : 1460px)  {
    .candidate-card-job-matches-count {
        width: 60px;
    }
 }

.candidate-info-container{
    grid-column: 1/-1;
}

/* ngIf animation */
.animate-if.ng-enter,
.animate-if.ng-leave {
    -webkit-transition: opacity ease-in-out 1s;
    -moz-transition: opacity ease-in-out 1s;
    -ms-transition: opacity ease-in-out 1s;
    -o-transition: opacity ease-in-out 1s;
    transition: opacity ease-in-out 1s;
}

.animate-if.ng-enter,
.animate-if.ng-leave.ng-leave-active {
    opacity: 0;
}

.animate-if.ng-leave,
.animate-if.ng-enter.ng-enter-active {
    opacity: 1;
}

.multiSelect > button {
    width: 100% !important;
    text-align: left !important;
}

.candidate-card-container .menu-items li a{
    height: 30px;
    line-height: 30px;
}

.candidate-card-loader-div {
    height: 247px;
    padding-top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    /* overflow: hidden; */
    border-radius: 3px;
	background-clip: padding-box;
    border: 1px solid #e7ebee;
}


.dark-green-bg {
	background-color: #4e9a06 !important;
}

.green-bg {
	background-color: #8bc34a !important;
}

.dark-blue-bg {
    background-color: #03a6f4 !important;
}

.blue-bg {
    background-color: #00ccff !important;
}

.grey-bg {
	background-color: #808080 !important;
}

.brown{
    color:brown;
}

.candidate-job-card-notification-icon a, .candidate-job-card-notification-icon a:hover, .candidate-job-card-notification-icon a:visited {
	border-bottom: 0px;
	color: white;
}

.candidate-job-card-notification-icon .btn {
    border-bottom: none;
    padding-left: 3px;
    padding-right: 8px;
}

#candidatesTitle {
	background-color: #f9f9f9;
}

#filterSearchCandidates {
	margin-top: 88px;
}

#checkscale {
	position: relative;
	top: -4px
}

#checkcircle {
	position: relative;
	top: -4px;
	margin-left: 7px;
}

.profile-box-notification {
    height: 30px;
}

.profile-box-menu .candidate-card-header {
    min-height: 154px;
    margin-top: 1px;
    padding-top: 5px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 10px;
    -webkit-border-radius: 0px 0px 0px 0px;
    -moz-border-radius: 0px 0px 0px 0px;
    border-radius: 0px 0px 0px 0px;
}

.candidate-vendor-card-header{
    min-height: 175px !important;
}

.candidate-card-name-div {
    margin-bottom: 5px;
    font-size: 1em;
}

.candidate-card-detail-div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1em;
}

.candidate-card-detail-div a {
    color: #fff;
}

.candidate-card-detail-div a:hover {
    color: #fff;
}

.candidate-card-detail-div a:visited {
    color: #fff;
}

.candidate-card-detail-div a:focus {
    color: #fff;
}

.wrap-candidate-name {
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60%;
    font-weight: 600;
    font-size: 1.2em;
}

.candidate-card-img {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    width: 45px;
    height: 45px;
    border: 1.5px solid #fff;
    margin-right: 10px;
}

.candidate-card-recruiter-img {
    border-radius: 50%;
    background-clip: padding-box;
    height: 25px;
    overflow: hidden;
    width: 25px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 12px;
}

.notification-count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    color:#fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 13px;
    position: absolute;
    right: 0px;
    text-align: center;
    top: 0px;
    width: 16px;
}

.candidate-card-recruiter-count {
    background: #03a9f4;
    color: #fff;
    opacity: .8;
    position: absolute;
    right: -20%;
    border-radius: 100px;
    padding: 3px 3px 3px 3px;
    text-align: center;
    font-weight: normal;
    font-size: 11px;
    line-height: 1;
    margin-right: 40px;
    margin-top: -35px;
}

.candidate-card-recruiter-name {
    font-size: 10px;
    padding: 5px 0px 5px 0px;
    line-height: 1;
	display: inline-block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
    width: 100%;
    color: #000000;
}

.candidate-card-recruiters-popover-content-container{
    display: flex; 
    flex-wrap: wrap;
}

.candidate-card-popovers-container .popover-content {
    white-space: nowrap !important;
}

.candidate-card-popover-recruiter {
    float: left;
    display: inline-block !important;
}

.no_candidates_found {
    height: 187px;
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
}

.no-candidates-found {
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
    padding-bottom: 50px;
}

.candidate-card-footer-section {
    margin-top: 15px;
}

.candidate-job-match-row {
    margin-top: 2px;
    border-top: 1px dotted;
}

.candidate-job-matches-popover {
    max-width: 700px;
    width: 550px;
    font-size: 1em;
}

.candidate-card-recruiters-popover {
    max-width: 500px;
    width: 300px;
    font-size: 1em;
}

.candidate-job-match-text {
    overflow: hidden;
    text-overflow: ellipsis;
}

.candidate-job-match-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875em;
    font-weight: 600;
}

.candidate-job-match-footer {
    padding-top: 10px;
}

.candidate-job-match-footer a, .candidate-job-match-footer a:hover, .candidate-job-match-footer a:visited {
    color: black;
}

.inert-candidate-current-job-details{
    padding: 5px;
    border-radius: 5px;
    font-size: 0.95em;
    background-color: #949494;
}

.candidate-dropdown> li> a{
    font-size: 14px;
}

.candidate-filter-close-icon {
    top: -11px;
    right: 11px;
    border-radius: 50%;
    color: #e84e40;
    background-color: white;
    border: none;
}

.candidate-selected-filter {
    width: fit-content;
    background-color: #03a9f4;
    color: white;
}

.candidate-salary-filter {
    height: 83px;
}

.salary-border-danger {
    border:1px red solid;
}

.salary-border-danger input:focus {
    box-shadow: red;
}

.candidate-card-row-view-head {
    background-color: #4a9be0;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}