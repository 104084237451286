.associate-vendors-disabled-bg{
    background-color: lightgray !important;
}

.associate-vendors-search-container{
    display:flex;
    flex-direction: row-reverse;
    margin: 10px 0 18px 0;
}

.associate-vendors-table-row {
    margin-top: 0.2em;
    border-top: 1px dotted;
    padding: 1em;
}

.associate-vendors-table-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875em;
    font-weight: 600;
}

.associate-vendors-table-header-row{
    padding: 0.2em 1em 0.2em 1em;
}