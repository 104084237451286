.mr-1 {
    margin-right: 7px;
}

.job-board-parameters-title {
    margin-top: 0;
    border-bottom: 0px;
    padding-left: 25px
}

.job-board-input-control {
    position: relative;
    bottom: 10px;
}

.job-boards-flags-header {
    margin-top: 15px;
    padding-left: 40px;
}

.job-board-flag {
    font-size: 17px;
}

.job-boards-actions-container {
    margin-bottom: 10px;
    margin-top: 20px
}

.job-boards-actions {
    display: flex;
    justify-content: flex-end;
}

.job-boards-edit-parameters-button {
    margin-right: 10px;
}

.job-boards-filters-tabs {
    margin-left: 25px;
    margin-right: 25px;
    border: 1px solid lightgrey;
}

.job-boards-filters-tab {
    padding: 25px;
}

.job-boards-advance-filter-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.job-boards-advance-filter-image {
    height: 400px;
    overflow: auto;
}

.job-boards-flags-values {
    margin-top: 10px
}