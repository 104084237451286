.wizard {
	display:none;
}
.wizard-modal form {
	margin:0;
	padding:0;
}
.wizard-modal.modal {
	width:750px;
	margin-left:-375px;
	top:50%;
	background: #fff;
	left: 50%;
	overflow: hidden;
	bottom: auto;
	position: fixed;
}
.wizard-modal-footer {
	padding:0;
}
.wizard-modal-header.modal-header h3 {
	border-bottom: 0 none;
    margin: 0 -15px;
    padding: 5px 0 5px 20px;
}
.wizard-modal-header.modal-header {
	border-bottom:0;
}

.wizard-subtitle {
	font-weight:bold;
	color:#AFAFAF;
	padding-left:20px;
	display: none;
}

.wizard-error,
.wizard-failure,
.wizard-success,
.wizard-loading,
.wizard-card {
	position:relative;
	padding:35px;
	padding-top:20px;
	overflow-y:auto;
	height:300px;
	display:none;
	border-top: 1px solid #EEE;
	margin-right: 5px;
}

.wizard-nav-link .icon-chevron-right {
	float:right;
	margin-top:12px;
	margin-right:-6px;
	opacity:.25;
}

li.wizard-nav-item.active .icon-chevron-right {
	opacity:1;
}

li.wizard-nav-item {
	line-height:40px;
	float: none;
}

.wizard-modal.modal .nav-list > li > a {
	background-color:#f5f5f5;
	padding:3px 15px 3px 20px;
	cursor:default;
	color:#B4B4B4;
}

.wizard-modal.modal .nav-list li.active > a {
	background-color:#3498db;
}

.wizard-modal.modal .nav-list > li.already-visited > a.wizard-nav-link {
	color:#3498db;
	cursor:pointer;
}

.wizard-modal.modal .nav-list > li.active > a.wizard-nav-link {
	color:white;
}

.already-visited > a.wizard-nav-link:hover {
	background-color:#E4E4E4;
}

.wizard-card > h3 {
	margin-top:0;
}

.wizard-progress {
	padding:15px;
	bottom:0;
}
.wizard-progress-container {
	padding:20px;
}

.wizard-steps {
	width:28%;
	height:425px;
	background-color:#f5f5f5;
}

.wizard-nav-container {
	height:360px;
}

.nav > li > a.wizard-step-error {
	background-color:#F2DEDE;
	color:#B94A48;
	font-weight:bold;
}

.wizard-step-error .icon-chevron-right {
	opacity:0;
}

.wizard-input-section {
	margin-bottom:10px;
}

.wizard-buttons-container {
	padding:20px;
}

.wizard-cancel {
	display:none;
	margin-left:20px;
}

.wizard-modal .popover.error-popover {
	background-color:#F2DEDE;
	color:#A94442;
	border-color:#DD504C;
}

.wizard-modal .popover.error-popover .arrow::after {
	border-top-color:#F2DEDE;
}

.wizard-modal .popover.error-popover .popover-title {
	display:none;
}

.wizard-modal .popover.error-popover .arrow {
	border-top-color:#DD504C;
}
