.send-assessment-statement{
    padding: 15px;
    padding-top: 0px;
    margin-bottom: 20px;
}

.send-assessment-highlight-time-to-complete-label{
    font-weight: bold;
}

.send-assessment-limit-warning-container{
    margin-bottom: 10px;
}

.send-assessment-limit-warning{
    padding:5px;
    border:1px solid lightgrey;
}

.send-assessment-select{
    position:absolute;
    top:-6px
}

.reminder-every-radio label:before{
    position: absolute;
    top: 6px;
}

.reminder-every-radio label:after{
    position: absolute;
    top: 7px;
}

.send-assessment-candidate-status-message {
    display: flex;
    justify-content: flex-start;
    margin: 3px; 
    padding: 3px; 
    padding-left: 8px; 
    border-radius: 5px;
}