.job-match-status-statement{
    padding: 15px;
    padding-top: 10px;
    margin-bottom: 20px;
}

.set-job-match-status-meeting-details-popover{
    max-width: 800px;
    width: 700px;
    font-size: 1em;
}

.set-job-match-status-meeting-details-meeting-row {
    margin-top: 2px;
    border-top: 1px dotted;
}

.set-job-match-status-meeting-details-header-text {
    font-size: 0.875em;
    font-weight: 600;
}

.set-job-match-status-meeting-icon-in-progress{
    color: #e88e12;
}

.set-job-match-status-meeting-icon-scheduled{
    color: #2153e8;
}

.set-job-match-status-meeting-icon-cancelled{
    color: #ff182c;
}

.set-job-match-status-meeting-icon-completed{
    color: #006416;
}

.set-job-match-status-meeting-icon-overdue{
    color: #e85e1d;
}

.set-job-match-status-loading-spinner {
    height: 300px;
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 100px;
}

.h-300{
    height: 300px !important;
}

.h-400{
    height: 400px !important;
}

.multi-select-error {
    border: 1px solid red;
}