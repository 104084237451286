/* -------------------------------- 

Modules - reusable parts of our design

-------------------------------- */
.cd-container {
	/* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
	width: 90%;
	max-width: 1170px;
	margin: 0 auto;
}
.cd-container::after {
	/* clearfix */
	content: '';
	display: table;
	clear: both;
}

#cd-timeline {
	position: relative;
	padding: 2em 0;
	margin-top: 10px;
	margin-bottom: 20px;
}
#cd-timeline::before {
	/* this is the vertical line */
	content: '';
	position: absolute;
	top: 0;
	left: 18px;
	height: 100%;
	width: 6px;
	background: #c2dcee;
}
@media only screen and (min-width: 1170px) {
	#cd-timeline {
		margin-top: 10px;
		margin-bottom: 20px;
	}
	#cd-timeline::before {
		left: 50%;
		margin-left: -2px;
	}
}

.cd-timeline-block {
	position: relative;
	margin: 2em 0;
}
.cd-timeline-block:after {
	content: "";
	display: table;
	clear: both;
}
.cd-timeline-block:first-child {
	margin-top: 0;
}
.cd-timeline-block:last-child {
	margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
	.cd-timeline-block {
		margin: 4em 0;
	}
	.cd-timeline-block:first-child {
		margin-top: 0;
	}
	.cd-timeline-block:last-child {
		margin-bottom: 0;
	}
}

.cd-timeline-img {
	background: #3498db;
	color: #fff;
	position: absolute;
	top: 0;
	left: 0;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	box-shadow: 0 0 0 6px #c2dcee;
}
.cd-timeline-img i {
	display: block;
	width: 32px;
	height: 24px;
	position: relative;
	left: 50%;
	top: 50%;
	margin-left: -16px;
	margin-top: -12px;
	line-height: 24px;
	text-align: center;
	font-size: 1.4em;
}
@media only screen and (min-width: 1170px) {
	.cd-timeline-img {
		width: 60px;
		height: 60px;
		left: 50%;
		margin-left: -29px;
		/* Force Hardware Acceleration in WebKit */
		-webkit-transform: translateZ(0);
		-webkit-backface-visibility: hidden;
	}
	.cd-timeline-img i {
		font-size: 2em;
	}
	.cssanimations .cd-timeline-img.is-hidden {
		visibility: hidden;
	}
	.cssanimations .cd-timeline-img.bounce-in {
		visibility: visible;
		-webkit-animation: cd-bounce-1 0.6s;
		-moz-animation: cd-bounce-1 0.6s;
		animation: cd-bounce-1 0.6s;
	}
}

@-webkit-keyframes cd-bounce-1 {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.5);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale(1.2);
	}

	100% {
		-webkit-transform: scale(1);
	}
}
@-moz-keyframes cd-bounce-1 {
	0% {
		opacity: 0;
		-moz-transform: scale(0.5);
	}

	60% {
		opacity: 1;
		-moz-transform: scale(1.2);
	}

	100% {
		-moz-transform: scale(1);
	}
}
@keyframes cd-bounce-1 {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.5);
		-moz-transform: scale(0.5);
		-ms-transform: scale(0.5);
		-o-transform: scale(0.5);
		transform: scale(0.5);
	}

	60% {
		opacity: 1;
		-webkit-transform: scale(1.2);
		-moz-transform: scale(1.2);
		-ms-transform: scale(1.2);
		-o-transform: scale(1.2);
		transform: scale(1.2);
	}

	100% {
		-webkit-transform: scale(1);
		-moz-transform: scale(1);
		-ms-transform: scale(1);
		-o-transform: scale(1);
		transform: scale(1);
	}
}
.cd-timeline-content {
	position: relative;
	margin-left: 60px;
	background: #fff;
	border-radius: 3px;
	padding: 1em;
	box-shadow: 1px 1px 2px 0 #cccccc;
}
.cd-timeline-content:after {
	content: "";
	display: table;
	clear: both;
}
.cd-timeline-content h2 {
	margin: 0;
	padding: 0;
	font-size: 1.5em;
	font-weight: 600;
}
.cd-timeline-content h2 > i{
	color:#3498db;
}
.cd-timeline-content h2 > span{
	padding-left: 10px;
}
.cd-timeline-content p, .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
	font-size: 1em;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
	display: inline-block;
}
.cd-timeline-content p {
	margin: 1em 0;
	line-height: 1.6;
	color: #4C5964;
}
.cd-timeline-content .cd-read-more {
	float: right;
	padding: .8em 1em;
	background: #acb7c0;
	color: white;
	border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
	background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
	float: left;
	padding: .8em 0;
	opacity: .7;
}
.cd-timeline-content::before {
	content: '';
	position: absolute;
	top: 16px;
	right: 100%;
	height: 0;
	width: 0;
	border: 7px solid transparent;
	border-right: 7px solid white;
}
.cd-timeline-content-multiple-p{
	padding-left: 35px;
}
@media only screen and (min-width: 768px) {
	.cd-timeline-content h2 {
	}
	.cd-timeline-content p {
		
	}
	.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
		font-size: 14px;
		font-size: 0.875rem;
	}
}
@media only screen and (min-width: 1170px) {
	.cd-timeline-content {
		margin-left: 0;
		padding: 1.6em;
		width: 45%;
	}
	.cd-timeline-content::before {
		top: 24px;
		left: 100%;
		border-color: transparent;
		border-left-color: white;
	}
	.cd-timeline-content .cd-read-more {
		float: left;
	}
	.cd-timeline-content .cd-date {
		color: #3498db;
	    font-size: 1.9em;
	    left: 121%;
	    position: absolute;
	    top: -12px;
	    width: 100%;
	}
	.cd-timeline-block:nth-child(even) .cd-timeline-content {
		float: right;
	}
	.cd-timeline-block:nth-child(even) .cd-timeline-content::before {
		top: 24px;
		left: auto;
		right: 100%;
		border-color: transparent;
		border-right-color: white;
	}
	.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
		float: right;
	}
	.cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
		left: auto;
		right: 121%;
		text-align: right;
	}
	.cssanimations .cd-timeline-content.is-hidden {
		visibility: hidden;
	}
	.cssanimations .cd-timeline-content.bounce-in {
		visibility: visible;
		-webkit-animation: cd-bounce-2 0.6s;
		-moz-animation: cd-bounce-2 0.6s;
		animation: cd-bounce-2 0.6s;
	}
}

@media only screen and (min-width: 1170px) {
	/* inverse bounce effect on even content blocks */
	.cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
		-webkit-animation: cd-bounce-2-inverse 0.6s;
		-moz-animation: cd-bounce-2-inverse 0.6s;
		animation: cd-bounce-2-inverse 0.6s;
	}
}
@media only screen and (max-width: 1170px) {
	.cd-timeline-content .cd-date {
		display: none;
	}
	
	.cd-timeline-content .btn {
		font-size: 12px;
		padding: 5px 10px;
	}
}
@-webkit-keyframes cd-bounce-2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100px);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateX(20px);
	}

	100% {
		-webkit-transform: translateX(0);
	}
}
@-moz-keyframes cd-bounce-2 {
	0% {
		opacity: 0;
		-moz-transform: translateX(-100px);
	}

	60% {
		opacity: 1;
		-moz-transform: translateX(20px);
	}

	100% {
		-moz-transform: translateX(0);
	}
}
@keyframes cd-bounce-2 {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100px);
		-moz-transform: translateX(-100px);
		-ms-transform: translateX(-100px);
		-o-transform: translateX(-100px);
		transform: translateX(-100px);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateX(20px);
		-moz-transform: translateX(20px);
		-ms-transform: translateX(20px);
		-o-transform: translateX(20px);
		transform: translateX(20px);
	}

	100% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}
}
@-webkit-keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100px);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateX(-20px);
	}

	100% {
		-webkit-transform: translateX(0);
	}
}
@-moz-keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
		-moz-transform: translateX(100px);
	}

	60% {
		opacity: 1;
		-moz-transform: translateX(-20px);
	}

	100% {
		-moz-transform: translateX(0);
	}
}
@keyframes cd-bounce-2-inverse {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100px);
		-moz-transform: translateX(100px);
		-ms-transform: translateX(100px);
		-o-transform: translateX(100px);
		transform: translateX(100px);
	}

	60% {
		opacity: 1;
		-webkit-transform: translateX(-20px);
		-moz-transform: translateX(-20px);
		-ms-transform: translateX(-20px);
		-o-transform: translateX(-20px);
		transform: translateX(-20px);
	}

	100% {
		-webkit-transform: translateX(0);
		-moz-transform: translateX(0);
		-ms-transform: translateX(0);
		-o-transform: translateX(0);
		transform: translateX(0);
	}
}

@media only screen and (min-width: 1170px) {
	.rtl .cd-timeline-img {
		margin-right: -29px;
		margin-left: 0;
	    right: 50%;
	    left: auto;
	}
	.rtl .cd-timeline-block:nth-child(2n) .cd-timeline-content:before {
	    border-color: transparent transparent transparent white;
	    right: auto;
	    left: 100%;
	}
	.rtl .cd-timeline-block:nth-child(2n) .cd-timeline-content {
	    float: left;
	}
	.rtl .cd-timeline-block:nth-child(2n) .cd-timeline-content .cd-date {
	    left: 121%;
	    right: auto;
	    text-align: left;
	}
}
.rtl .cd-timeline-img i {
	margin-right: -16px;
	margin-left: 0;
	right: 50%;
    left: auto;
}
.rtl .cd-timeline-content:before {
    border-color: transparent white transparent transparent;
}
.rtl .cd-timeline-content .cd-date {
    right: 121%;
    left: auto;
}