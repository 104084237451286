.meeting-icon-in-progress{
    color: #e88e12;
}

.meeting-icon-scheduled{
    color: #2153e8;
}

meeting-icon-overdue{
    color: #e85e1d;
}

.meeting-icon-cancelled{
    color: #ff182c;
}

.meeting-icon-completed{
    color: #006416;
}

.disabled-meeting-label{
    color: #828181;
}