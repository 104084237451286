@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?gzc551');
  src:  url('../fonts/icomoon.eot?gzc551#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?gzc551') format('truetype'),
    url('../fonts/icomoon.woff?gzc551') format('woff'),
    url('../fonts/icomoon.svg?gzc551#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-icon_4dot5:before {
  content: "\e900";
}

/* Colors  */

.lightblue {
	color : rgba(69, 195, 255, 0.74) !important;
}
/* Color for passive candidate*/
.emerald-bg-passive-candidate {
    background-color: rgba(69, 195, 255, 0.74) !important;
}

/* Font Sizes */

.oneemfontsize {
	font-size : 1em !important;
}

.onepointoneemfontsize {
	font-size : 1.1em !important;
}

.twoemfontsize {
	font-size : 2em !important;
}

.twopointfiveemfontsize {
	font-size : 2.5em !important;
}

.threeemfontsize {
	font-size : 3em !important;
}
.displaynone {
	display: none !important;
}

.pointer {
	cursor: pointer !important;
}

.nopointerevents {
	pointer-events : none !important;
}

.textcursor {
	cursor: text;
}

.marginrightzero {
	margin-right: 0px !important;
}

.marginleftzero {
	margin-left: 0px !important;
}

.paddingleftzero{
	padding-left:0px !important;
}

.disablescreen {
	pointer-events : none !important;
	opacity : 0.6 !important;
}
.borderbottomnone {
	border-bottom: none !important
}

.x-ng-cloak,
[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.ng-cloak {
  display: none !important;
}

.theme-whbl {
    padding-right: 0px !important;
}

.passive-candidate-state-column {
    width: 24.9% !important;
}

.btn:disabled {
    pointer-events:none;
}

.active-candidate-state-column {
    width: 24.9% !important;
}

.fullwidth {
    width : 100% !important;
}

.error {
    color: red;
}

.iconinicon {
    display: block !important;
    margin-left: 5px !important;
    margin-top: 5px !important;
}

.borderless tr td, .borderless tr th {
    border: none;
}

.approw {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.wizard {
    display: block !important;
}

.formfirstsideelement {
    padding-left: 0px !important;
}

.formsecodesideelement {
    padding-right: 0px !important;
}

.todooverride > li input:checked + label {
    text-decoration: initial !important;
}

em {
    color: red;
}

.typeahead,
.tt-query,
.tt-hint {
    width: 100% !important;
}

.input-group[class*="col-"] {
    float: none;
    padding-left: 8px;
    padding-right: 8px;
}

/* for making the banner to show on top of the spinnerbar */
.ngn{
    z-index: 500000 !important;
}


@media (max-width: 991px) {
    /*.vertilizeheight {
        height: 0px !important;
    }*/
}

#sidebar-nav .nav li .submenu :hover {
    display: block !important;
}

.buttonrow {
    margin-bottom: 50px !important;
}

.missioncontrol-footer {
    background-color: #f9f9f9;
}

.wizardmainbox {
    padding: 0px !important;
}

.requistion-card-notification-icon > a > i {
    color: #fff !important;
}

.cardlistactivities {
    padding: 0px 17px !important;
}

.disablecheckboxselection:before {
    background: #eeeff1 !important;
}

.disablecheckboxselection:after {
    opacity : 0.4 !important;
}

.requistion-card-notification-icon > a > span.count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    color: #fff;
    display: block;
    font-size: 9px;
    height: 14px;
    line-height: 14px;
    position: absolute;
    right: 5px;
    text-align: center;
    top: 2px;
    width: 14px
}

.bottomgap {
    margin-bottom: 4% !important;
}

.filter-searchbox {
    width: 100%;
    height: 34px;
    display: block;
    margin-top: 25px;
}

#filterSearch {
    margin-top: 50px;
}

/*@media (min-width: 992px){
    .missioncontrol-footerbar {
        position: relative !important;
        width: inherit !important;
    }
}*/
/* .footer-content-wrapper {
    min-height: 0px !important;
} */

.candidate-profile-box-contact .candidate-name {
    padding: 8px 0 3px;
    margin: 0;
    font-weight: 600;
    line-height: 1.1;
    font-size: 1em;
    cursor: pointer;
}

.candidate-profile-box-contact .candidate-name.text-overflow-wrapper,
.candidate-profile-box-contact .job-position.text-overflow-wrapper,
li.text-overflow-wrapper {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.candidate-profile-box-contact .profile-box-footer {
    padding: 10px 10px 15px;
}

.panel-custom {
    color: #fff !important;
    background-color: #03a9f4 !important;
    border-color: #03a9f4 !important;
}

.fontsize {
    font-size: large;
}

.rolecard {
    overflow-y: hidden !important;
}

.rowheight {
    height: 1030px;
}

.pricing-package:hover .pricing-package-inner .package-content .candidate .package-top-features
.package-features .match {
    color: #212121;
}
/*
    Increased because of setting page. Which is very huge
  */
/* .heig {
    height:1800px !important;
} */
/* .hig {
    height:1340px ;
} */
.inputBoxBorder {
    border: 1px solid #ccc;
}
.searchButtonColumn {
    margin-left: -8px;
}
.searchButtonFilter {
    padding-right: 20px;
}
.inputBoxTransactionRequisition {
    width: 100%;
    height: 34px;
    display: block;
}
.intl-tel-input {
    display: block;
}
.heading-css{
    /* font-size: x-large !important; */
    font-weight: bold;
}

.highlight-name{
    color: #03a9f4;
}

.borderlesstable td, .borderlesstable th {
    border: none !important;
}

.threehundredfontweight {
    font-weight : 300;
}

.wrap-word{
    width: 100px;
    word-break: break-all;
    white-space: normal;
}

.main-box-footer {
    min-height: 50px;
    padding: 10px 20px;
}

.align-middle{
    vertical-align: middle;
}

.loading-spinner {
    height: 187px;
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
}

.popover-loading-spinner {
    font-size: 20px;
    color: gray;
    padding-top: 0px;
    padding-bottom: 10px;
}

.popover-footer-link {
    background-color:#e7ebee; 
    font-size:13px !important;
}

.cursor-default {
    cursor: default;
}

.dropdown-option {
    padding: 1px 12px!important;
    font-size: .875em!important;
    color: #000;
    cursor: pointer;
}

.loading-message {
    text-align: center;
    display: block;
    font-size: 17px;
} 

.boot-box-dialog-loading-message {
    display: flex;
    align-items: center;
    justify-content: center;
}

.pointer-events-none {
    pointer-events: none;
}

.action-menu-item{
    padding-left:15px !important;
}

.entity-upload-failed-icon {
    position: relative;
    right: 4px;
}

.file-format-content{
    color:gray;
}

.main-modal-header{
    padding: 15px !important; 
    border-bottom: 1px solid #e5e5e5 !important;
}

.workflow-icon{
    height:15px;
    opacity: 0.7;
    padding-left:1px;
}

.workflow-filter-icon{
    font-size: 9px;
    position: relative;
    bottom: 6px;
    right: 3px;
}

.date-range-picker-input{
    display: block;
    position: relative;
    text-align: center;
    cursor: pointer;
    border: 1px solid #c6c6c6;
    padding: 7px 8px 1px 0px;
    font-size: 14px;
    min-height: 38px!important;
    border-radius: 4px;
    color: #555;
    white-space: normal;
    background-color: #fff;
    background-image: linear-gradient(#fff,#f7f7f7);
}

#loading-modal {
    position:fixed;
    width:100%;
    left:0;right:0;top:0;bottom:0;
    background-color:rgba(0,0,0,0.4);
    z-index:9999;
    justify-content: center;
    align-items: center;
    display:none;
}

#loading-modal-content{
    font-size: 2rem;
    background-color: white; 
    border-radius: 10px; 
    padding: 2rem;
    position: relative;
    bottom: 7rem;
}

.opacity-half{
    opacity: 0.5 !important;
}

.conference-container {
    border: 1px solid lightgray; 
    border-radius: 10px;
  }

  .highlight-search-button::before {
    content: '';
    border-radius: 700px;
    border: 4px solid #03a9f4;
    box-shadow: 0 0 60px #03a9f4;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all .3s ease-in-out 0s;
  } 
  
  .highlight-search-button::after {
    content: '';
    width: 30px; height: 30px;
    border-radius: 100%;
    border: 4px solid  #03a9f4;
    position: absolute;
    top: 50%;
    left: 27%;
    transform: translate(-50%, -50%);
    animation: highlight-ring 1.5s infinite;
  }
  
  .highlight-search-button:hover::after, .highlight-search-button:focus::after {
    animation: none;
    display: none;
  }

  @keyframes highlight-ring {
    0% {
      width: 30px;
      height: 30px;
      opacity: 1;
    }
    100% {
      width: 100px;
      height: 100px;
      opacity: 0;
    }
  }

  .close:focus, .close:hover  {
      outline: #fff;
  }

/* width */
::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ccc; 
    border-radius: 14px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888; 
  }

  .width-25 {
    width: 25%;
  }

  .width-50 {
      width: 50%;
  }

  .width-75 {
    width: 75%;
  }

  .width-100 {
    width: 100%;
  }
  
  .width-fit-content {
    width: fit-content;
  }

  .max-width-fit-content {
    max-width: fit-content;
  }

  .two-line-text-overflow-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
 }

 .text-dark {
     color:#000;
 }

 .bg-purple {
     background-color: #e5d8ffdb;
 }

 .font-bold {
     font-weight: 600;
 }

.html-editor h1 {
    color: black;
}

.html-editor h3 {
    border-bottom: 0;
}

.html-editor .btn-default {
    color: #333 !important;
    background-color: #ebebeb;
    border-color: #adadad;

}

.html-editor .btn-default:hover,
.html-editor .btn-default:focus,
.html-editor .btn-default:active,
.html-editor .btn-default:hover {
    color: #333;
    background-color: #ebebeb;
    border-color: #adadad;
}

.html-editor .ta-scroll-window {
    height: 300px;
    overflow: auto;
}

.html-editor .ta-scroll-window div:last-child {
    height: 100%;
    outline: none;
}
