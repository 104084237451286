
.folder:hover, .folder-active {
    background-color: rgba(147, 197, 253,0.3);
    color:black !important;
}

.available-documents-container {
    display: -ms-flexbox; /* IE10 */
    display: flex;
    -ms-flex-wrap: wrap; /* IE10 */
    flex-wrap: wrap;
    padding: 0 4px;
    height: 550px;
    max-height: 550px;
    /* overflow-y: auto; */
    align-content: flex-start;
}

.available-document {
    /* -ms-flex: 23%;
    flex: 23%;
    max-width: 23%; */
  padding: 0 4px;
  /* margin-right:10px; */
  margin-bottom:10px;
  max-height: 176px;
}

/* @media screen and (max-width: 1330px){
    .available-document {
        -ms-flex: 31%;
      flex: 31%;
      max-width: 31%;
      padding: 0 4px;
      margin-right:10px;
    }
}

@media screen and (max-width: 950px){
    .available-document {
        -ms-flex: 45%;
      flex: 45%;
      max-width: 45%;
      padding: 0 4px;
      margin-right:10px;
    }
} 
 
.available-document:hover {
    background-color: rgba(147,197,253,.3);
}  */

.available-document:hover {
    background-color: rgba(147,197,253,.3);
} 

.custom-padding {
    padding-top: 7px;
    padding-bottom: 7px;
}

.tree-node-content-custom-class {
    color: gray;
    background-color: #d3d3d34d !important;
    cursor: pointer;
    border-radius: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
}

.tree-node-content-custom-class:hover, .active-available-documents-folder {
    color: rgb(85, 84, 84);
    background-color: #e9e9e9 !important;
}