.assessments-sync-message {
    position: fixed;
    z-index: 10000;
    background: darkslategrey;
    color: white;
    padding: 20px;
    bottom: 1em;
    left: 5em;
} 
.assessments-sync-message-close{
    position: absolute;
    top: 2px;
    right: 6px;
}