.job-candidates-compare-salary-success-icon {
    color: #8bc34a;
    font-size: 1.2em;
}

.job-candidates-compare-salary-danger-icon {
    color: #e84e40;
    font-size: 1.2em;
}

.job-candidates-compare-salary-flag {
    margin-left: -27px;
}

.comparison-panel-heading {
    font-weight: 500;
    padding-left: 20px;
    display: inline-block;
}


.candidate-compare {
}

.candidate-compare .candidate-compare-inner {
    color: #7d7d7d;
    position: relative;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

.candidate-compare .pricing-star {
    background: url(../../../../../../../../assets/app-content/img/pricing-star.png) no-repeat;
    position: absolute;
    width: 39px;
    height: 40px;
    color: #fff;
    font-size: 0.65em;
    text-align: center;
    line-height: 1.1;
    padding-top: 9px;
    right: -10px;
    top: -20px;
}

.candidate-compare .package-header {
    background: #03a9f4;
    border-radius: 2px 2px 0 0;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

.candidate-compare .package-header h3 {
    line-height: 1;
    text-align: center;
    color: #fff;
    margin: 0;
    padding: 20px 0 0;
    border: 0;
    font-size: 1.2em;
    text-transform: uppercase;
    font-weight: 400;
}

.candidate-compare .package-header .stars {
    color: #fff;
    text-align: center;
    padding-top: 8px;
    display: none;
}

.candidate-compare .package-content {
    padding: 0;
    margin: 0;
    border: 1px solid #e7ebee;
    border-top-color: transparent;
    border-radius: 0 0 2px 2px;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
}

.candidate-compare .package-content .package-price {
    font-weight: bold;
    text-align: center;
    height: 80px;
    line-height: 75px;
    background: #03a9f4;
    color: #fff;
    margin: -1px;
    position: relative;
}

.candidate-compare .package-content .package-price .package-month {
    font-size: 1em;
    font-weight: normal;
}

.candidate-compare .package-content .package-price .price {
    font-size: 4em;
    font-weight: 400;
}

.candidate-compare .package-content .package-price .currency {
    font-size: 1.5em;
    font-weight: 600;
}

.candidate-compare .package-top-features {
    list-style: none;
    margin: 0;
    padding: 0;
}

.candidate-compare .package-top-features li {
    border-bottom: 1px solid #e7ebee;
    text-align: center;
    font-size: 1.125em;
    line-height: 1.5;
    padding: 8px 0;
}

.candidate-compare .package-top-features li:first-child {
    border-top: 1px solid #e7ebee;
}

.candidate-compare .candidate-compare-list {
    list-style: none;
    margin: 0px;
    /* margin: 0 18px; */
    padding: 0px 19px;
    /* padding: 15px 0px; */
    position: relative;
    top: 4px;
    transition: height 1s linear;
    border: 1px solid lightgray;
}

.candidate-compare .candidate-compare-list li {
    font-size: 0.875em;
    padding: 6px 0;
    line-height: 1.3;
    color: #7d7d7d;
    padding-left: 25px;
}

.candidate-compare .candidate-compare-list li.has-feature {
    transition: color 0.1s ease-in-out 0s;
}

.candidate-compare .candidate-compare-list li.has-nofeature {
    /*text-decoration: line-through;*/
}

.candidate-compare .candidate-compare-list li.additional {
    transition: color 0.5s ease-in-out 0s;
}

.candidate-compare .candidate-compare-list li.almost {
    transition: color 0.5s ease-in-out 0s;
}

.candidate-compare .candidate-compare-list li.has-feature:before {
    content: "\f164";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #8bc34a;
    margin-top: -2px;
    position: absolute;
    left: 13px;
    text-decoration: none;
}

.candidate-compare .candidate-compare-list li.has-nofeature:before {
    content: "\f165";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #e84e40;
    margin-top: -2px;
    position: absolute;
    left: 13px;
    text-decoration: none;
}

.candidate-compare .candidate-compare-list li.additional:before {
    content: "\f055";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #03a9f4;
    margin-top: -2px;
    position: absolute;
    left: 13px;
    text-decoration: none;
}

.candidate-compare .candidate-compare-list li.almost:before {
    content: "\f252";
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    font-size: 1.3em;
    text-decoration: inherit;
    color: #ffc107;
    margin-top: -2px;
    position: absolute;
    left: 13px;
    text-decoration: none;
}

.candidate-compare .package-footer {
    text-align: center;
    padding: 10px 0 25px;
}

.candidate-compare .package-footer .btn>span {
    padding-right: 6px;
    transition: color 0.1s ease-in-out 0s;
}

.candidate-compare .package-footer .btn:hover>span {
    color: #fff;
}

.candidate-compare.simple .package-header h3 {
    font-size: 1.3em;
    padding: 0;
    line-height: 42px;
    height: 42px;
}

.candidate-compare.simple .package-content .package-price {
    font-size: 1.8em;
    height: 60px;
    line-height: 60px;
    background: transparent;
    color: inherit;
}

.candidate-compare.simple .package-top-features li {
    font-size: 0.875em;
}

.candidate-compare.simple .package-top-features li:last-child {
    border-bottom: 0;
}

.candidate-compare:hover .candidate-compare-inner {
    background-color: #fcfcfc;
    color: #212121;
}

.candidate-compare:hover .candidate-compare-list li.has-feature {
    color: #212121;
}

.sticky-header {
    position: sticky; 
    top:0; 
    z-index: 9999;
}

.no-score {
    font-size: 12px;
    background: #7d7d7d;
    display: inline-block;
    width: 24px;
    height: 2px;
}

.step-heading {
     width: 21rem;
}

.job-candidates-compare-modal-extra-large > .modal-dialog{
    width: 85%;
}
.job-candidates-compare-modal-extra-large > .modal-dialog > .modal-content{
    margin-top: 1%;
}

.main-job-match-accordion .panel-body {
    padding: 0;
}

.job-match-accordion {
    position: relative;
    bottom: 20px;
}

.candidate-compare-container .panel-title{
    background-color: #f9f9f9;
}

.job-match-accordion .panel-title {
    background-color: #03a9f417;
}

.job-match-accordion .panel-body {
    padding: 10px 0px;
}
 
.skills-count {
    font-size: 14px;
}

.skill-count {
    width: 40px;
    display: inline-block;
}

.candidate-compare-list h5 {
    position: relative;
    bottom: 4px;
}

.panel-title-candidate-info {
    position:relative; 
    top:5px;
    font-size: 14px;
}