.job-profile-header{
    margin-bottom: 20px;
}

.job-profile-assets {
    font-size: 0.95em;
}

.job-profile-assets i {
    font-size: 1.5em;
    display: block;
    float: left;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    background-clip: padding-box;
    text-align: center;
}

.job-profile-assets .asset-value {
    color: #03a9f4;
}

.job-profile-name{
    font-size: 1.6em;
}

.job-profile-download-requisition-icon{
    font-size: 0.9em;
}

.job-profile-download-requisition-icon a:hover{
    text-decoration: none;
}

.job-profile-download-requisition-icon a:focus{
    text-decoration: none;
}

.job-profile-skill-required-indicator{
    font-size: 16px;
    top: 1px;
}

.fa-stack-job-download-icon {
    position: relative;
    top: 3px;
}