.four-dot-five-modal-full-screen > .modal-dialog{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.four-dot-five-modal-full-screen > .modal-dialog> .modal-content{
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}
.four-dot-five-modal-extra-large > .modal-dialog{
    width: 85%;
}
.four-dot-five-modal-extra-large > .modal-dialog > .modal-content{
    margin-top: 1%;
}
.four-dot-five-modal-large > .modal-dialog{
    width: 75%;
}
.four-dot-five-modal-large > .modal-dialog > .modal-content{
    margin-top: 1%;
}
.four-dot-five-modal-medium > .modal-dialog{
    width: 65%;
}
.four-dot-five-modal-medium > .modal-dialog > .modal-content{
    margin-top: 1%;
}
.four-dot-five-modal-small > .modal-dialog{
    width: 50%;
}
.four-dot-five-modal-small > .modal-dialog > .modal-content{
    margin-top: 10%;
}
.four-dot-five-modal-extra-small > .modal-dialog{
    width: 40%;
}
.four-dot-five-modal-extra-small > .modal-dialog > .modal-content{
    margin-top: 15%;
}