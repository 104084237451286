.add-candidates-from-job-boards-modal > .modal-dialog > .modal-content{
    margin-top:5%;
}
.action-menu-item{
    padding-left:15px !important;
}
.workflow-shared-requisition-badge{
    background-color: mediumpurple;
}
.workflow-header{
    display: flex; 
    justify-content: space-between;
}
.workflow-refresh-button{
    background-color: limegreen;
    border-color: green;
}
.workflow-openings-icon{
    font-size: 18px;
    padding-left: 10px;
}
.workflow-shared-requisition-openings{
    color: mediumpurple;
}
.work-flow-req-details-container{
    margin-left: 290px;
}
.workflow-openings-icons-container{
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 3px;
}
.workflow-disabled-global-action{
    pointer-events: none;
    opacity: 0.6;
}

.candidate-assessment-details-pop-over {
    color: black;
    width: 500px;
}

.candidate-job-match-tech-assessment-icon {
    font-weight: 1000;
    font-style: italic;
    transform: rotateX(18deg);
}

.candidate-job-match-assessment-icons {
    line-height:inherit;
    font-size:0.95em;
}

.candidate-job-match-selected-filter-close-icon {
    top: -11px;
    right: 11px;
    border-radius: 50%;
    color: #e84e40;
    background-color: white;
    border: none;
}

.candidate-job-match-selected-filter {
    width: fit-content;
    background-color: #03a9f4;
    color: white;
}
.candidate-work-flow-primary-hiring-manager-icon {
    font-size: 10px;
    position: relative;
    left: 0px;
    bottom: 5px;
}

.candidate-workflow-superscript {
    line-height: inherit;
    font-size: 0.95em;
    position: relative;
    bottom: 5px;
    padding: 0px 6px;
}

.candidate-job-match-card-not-available-icon {
    font-size: 10px;
    position: absolute;
    top: 1px;
}