/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import './assets/app-content/css-overrides/theme-override.css';
@import './assets/app-content/css-overrides/uib-modal-override.css';
@import './assets/app-content/css-overrides/b4-utility-classes.css';
@import './assets/app-content/css-overrides/jquery.nouislider-override.css';
@import './assets/app-content/css-overrides/dnd-override.css';

/* Component Styles */
@import './app/public/app/components/component-style.css';

/* Directive Styles */
@import './app/public/app/directives/directive-styles.css';

/* Shared Styles */
@import './app/public/app/shared/shared-styles.css';

body {
    overflow-y: auto !important;
}

.modal-open {
    overflow: hidden !important;
}

.z-index-1100 {
    z-index: 1100;
}

.candidate-custom-popover-container {
    background-color: white;
}

.candidate-custom-popover-title {
    padding: 5px 14px;
    margin: 0;
    font-size: 16px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0;
    text-align: left;
}

.candidate-custom-popover-content {
    padding: 9px 14px;
    margin: 0;
    font-size: 0.95em;
    line-height: 1.42;
}

/* TOASTR BUGFIX */
#toast-container>div {
    opacity: 1;
    z-index: 1000;
    position: absolute;
    top: 10vh;
    right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.toast-close-button {
    position: absolute;
    right: 5px;
    border: none;
    font-size: 20px;
    color: darkgray;
    float: right;
    background-color: transparent;
}

.toast-message {
    float: left;
    margin-right: 20px;
}

.toast {
    font-size: initial !important;
    border: initial !important;
    backdrop-filter: blur(0) !important;
}

.toast-success {
    padding: 15px 10px;
    color: #51A351 !important;
    background-color: #c3e7c3 !important
}

.toast-error {
    padding: 15px 10px;
    color: #BD362F !important;
    background-color: #c3e7c3 !important
}

.toast-info {
    padding: 15px 10px;
    color: #2F96B4 !important;
    background-color: #c3e7c3 !important
}

.toast-warning {
    padding: 15px 10px;
    background-color: #F89406 !important;
    background-color: #c3e7c3 !important
}

/* Ng-select custom styles */
.custom-ng-select-btn {
    display: inline;
    text-align: center;
    cursor: pointer;
    border: 1px solid #ccc;
    font-size: 13px;
    border-radius: 2px;
    color: #666;
    background-color: #f1f1f1;
    padding: 4px 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
    margin: 8px 8px 0px 8px;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, 
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    /* color: #333;
    background-color: #ebf5ff; */
    background-image: linear-gradient(#e9e9e9,#f1f1f1);
    color: #555;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
    /* background-color: #f5faff;
    color: #333; */
    color: #fff;
    background-color: #999;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected .ng-option-label, 
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked .ng-option-label {
    font-weight: 500;
}

.do-not-hire-for-clients .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    flex-wrap: nowrap;
}

.ng-dropdown-panel .ng-dropdown-panel-items {
    margin-bottom: 6px;
}

.user-communication-custom-ng-select .ng-dropdown-panel {
    width: 150%;
    margin-top: 2px;
}

.user-communication-custom-ng-select.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    white-space: wrap;
}

/* Custom ngx-bootstrap popover */
.requisition-assessment-details-pop-over {
    min-width: 500px;
    left: -200px !important;
}

.requisition-assessment-details-pop-over > .popover-arrow.arrow {
    left: 440px !important;
}

.assessment-info-notes-icon.top {
    top: -15px !important;
    left: 5px !important;
}

.assessment-info-notes-icon .popover-content {
    background-color: #add8e66e !important;
}

.assessment-info-email-delivery-popover.left {
    top: 4px !important;
    left: -12px !important;
}

/* Custom ngx-bootstrap modal */
.modal.show {
    opacity: 1;
}

.ngx-modal-header {
    padding: 15px 15px 35px 15px;
    border-bottom: 1px solid #e5e5e5;
}

.additional-details-pop-over {
    color: black;
    max-width: 320px;
}

.candidate-job-match-card-popover {
    min-width: 150px;
}

.custom-ngx-modal {
    margin-top: 12%;
}

.custom-modal-xl {
    width: 75% !important;
}

.custom-modal-65 {
    width: 65% !important;
}

/* Custom Tooltip */
.candidate-custom-popover {
    border: 1px solid rgba(0, 0, 0, .2) !important;
    border-radius: 6px !important;
    box-shadow: 0 5px 10px rgb(0 0 0 / 20%) !important;
    z-index: 1081 !important;
    padding: 0px;
}

.candidate-custom-popover .tooltip-inner {
    background: white !important;
    color: black !important;
    opacity: 1 !important;
    padding-right: 0px;
    padding-left: 0px;
}

.candidate-custom-popover.tooltip.in {
    opacity: 1 !important;
}

/* Custom Tooltip Bottom */

.candidate-custom-popover.tooltip.bottom {
    padding: 0px !important;
    margin-top: 7px !important;
}

.candidate-custom-popover.tooltip.bottom .tooltip-arrow.arrow {
    border-width: 0 10px 10px !important;
    top: -9px !important;
}

.candidate-custom-popover.tooltip.bottom .tooltip-arrow {
    border-bottom-color: white !important;
}

/* Custom Tooltip Top */

.candidate-custom-popover.tooltip.top {
    padding: 0px !important;
    margin-bottom: 7px !important;
}

.candidate-custom-popover.tooltip.top .tooltip-arrow.arrow {
    border-width: 10px 10px 0 !important;
    bottom: -9px !important;
}

.candidate-custom-popover.tooltip.top .tooltip-arrow {
    border-top-color: white !important;
}

/* Custom Tooltip Left */

.candidate-custom-popover.tooltip.left {
    padding: 0px !important;
    margin-right: 7px !important;
}

.candidate-custom-popover.tooltip.left .tooltip-arrow.arrow {
    border-width: 10px 0 10px 10px !important;
    right: -9px !important;
}

.candidate-custom-popover.tooltip.left .tooltip-arrow {
    border-left-color: white !important;
}

/* Custom Tooltip Right */

.candidate-custom-popover.tooltip.right {
    padding: 0px !important;
    margin-left: 9px !important;
}

.candidate-custom-popover.tooltip.right .tooltip-arrow.arrow {
    border-width: 10px 10px 10px 0 !important;
    left: -9px !important;
}

.candidate-custom-popover.tooltip.right .tooltip-arrow {
    border-right-color: white !important;
}

/* Skeleton Loading */
.skeleton-loading {
    background: #eee;
    background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
    border-radius: 5px;
    background-size: 200% 100%;
    animation: 1.5s shine linear infinite;
}

.skeleton-content {
    height: 60px;
}

.skeleton-container {
    width: 100%;
}

.skeleton-img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
}

.skeleton-img-candidate-card {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.skeleton-heading {
    height: 15px;
}

.skeleton-paragraph {
    height: 13px;
}

@keyframes shine {
    to {
        background-position-x: -200%;
    }
}

/* Custom Ngx-Datatables */
.ngx-datatable .datatable-header {
    border-bottom: 1px solid black !important;
}

.ngx-datatable .datatable-body .datatable-body-row {
    border-bottom: 0.5px solid #8080802b !important;
}

.ngx-datatable .datatable-header .datatable-header-inner .datatable-header-cell {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: black !important;
}

.ngx-datatable .datatable-body .datatable-body-row .datatable-body-cell {
    font-size: 0.875em !important;
    font-weight: 400 !important;
    color: #212121 !important;
}

.datatable-footer .datatable-footer-inner .page-count {
    font-size: 14px;
    font-weight: bold;
}

.datatable-footer {
    border: none !important;
}

.datatable-footer .datatable-pager li a:last-child {
    border: none !important;
}

.manage-skills-datatable.ngx-datatable.scroll-vertical {
    height: 70vh;
    max-height: 450px;
}

.assessment-datatable.ngx-datatable.scroll-vertical {
    height: 100%;
}

.assessment-datatable.ngx-datatable .datatable-body .datatable-body-row>div {
    text-align: center;
    align-items: center;
    padding: 6px 0px;
}

.assessment-datatable.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell, 
.assessment-datatable.ngx-datatable.material .datatable-header .datatable-header-cell {
    text-align: center !important;
    padding: 0px !important;
}

.assessment-datatable.ngx-datatable .datatable-header {
    display: flex !important;
    align-items: center;
}

/* Custom dropdown */
.activities-dropdown .dropdown-menu {
    inset: 100% 0px auto auto !important;
}

.manage-requisition-entities.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell {
    text-wrap: wrap !important;
}

/* Custom tags-input */
.ng2-dropdown-menu {
    z-index: 1100 !important;
}

.ng2-tag-input .ng2-tags-container tag {
    padding: 0px !important;
}

.make-application-user .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input {
    min-width: 200px !important;
}

.add-employee-belongs-to .ng2-tag-input .ng2-tags-container .ng2-tag-input__text-input {
    min-width: 400px !important;
}

/* Custom Data tables */

thead>tr>th.un-assigned-assessments-table.sorting.sorting_desc::before,
thead>tr>th.un-assigned-assessments-table.sorting.sorting_asc::before {
    content: none !important;
}

#unAssignedAssessmentsTable>thead>tr>th:nth-child(6),
#assignedAssessmentsTable>thead>tr>th:nth-child(6) {
    white-space: nowrap !important;
}

#unAssignedAssessmentsTable_filter>label>input[type=search],
#assignedAssessmentsTable_filter>label>input[type=search] {
    width: 280px !important;
    height: 30px !important;
    border: 1px solid #ccc !important;
    text-align: center !important;
    font: inherit !important;
}

.un-assigned-assessment-table>.dt-loading {
    display: none !important;
}

.dataTables_filter input,
.dataTables_filter input[type="search"] {
    width: 300px;
    height: 33px;
    border: 1px solid #ccc;
    text-align: center;
    font: inherit;
}

a.dt-button {
    font-size: 14px;
    padding: 0.4em 1em;
}

.dataTables_filter input,
.dataTables_filter input[type="search"] {
    font-size: 14px;
}

a.datatablebutton {
    color: #fff !important;
    background-color: #03a9f4 !important;
    border-color: #2980b9 !important;
    background-image: none !important;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-right: 20px;
}

table.table thead .sorting_desc:before,
table.table thead .sorting_asc:before {
    display: none;
}

.table thead>tr>th {
    text-transform: none !important;
}

div.dt-buttons {
    margin-top: 10px;
    margin-right: 10px;
    float: none;
    display: inline-block;
}

.dataTables_wrapper .text-right .dataTables_filter input {
    margin-left: 0px;
}

@media screen and (max-width: 1300px) {
    #clienttable {
        font-size: 0.9em;
    }
}
/* Client or Bu page Custom Styles of datatable */

.client-or-bu-table .dataTables_wrapper .dataTables_paginate .paginate_button.disabled {
    cursor: not-allowed !important;
}

.client-or-bu-table .dataTables_wrapper .dataTables_paginate .paginate_button {
    border: 1px solid #ddd !important;
    color: #03a9f4 !important;
    margin: 0px !important;
    border-radius: 0px !important;
}

.client-or-bu-table .dataTables_wrapper .dataTables_paginate .paginate_button:hover {
    background: none !important;
    background-color: #eee !important;
    border-color: #ddd !important;
}

.client-or-bu-table .dataTables_wrapper .dataTables_paginate .paginate_button:active {
    box-shadow: none !important;
}

.client-or-bu-table .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #03a9f4 !important;
    cursor: default;
}

.client-or-bu-table .dataTables_wrapper .dataTables_paginate {
    padding-top: 0.5em !important;
}