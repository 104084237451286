/* Growl-style notifications */
.ns-attached {
    left: 30px;
    max-width: 300px;
}

[class^="ns-effect-"].ns-attached.ns-hide,
[class*=" ns-effect-"].ns-attached.ns-hide {
    -webkit-animation-direction: reverse;
    animation-direction: reverse;
}

/* Individual effects */

/* Flip */
.ns-effect-flip {
    background: #9bd1a2;
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    bottom: 0px;
    font-size: 1.15em;
    padding: 1.5em;
    max-width: 280px;
    backface-visibility: hidden;
}

.ns-effect-flip .ns-close::before,
.ns-effect-flip .ns-close::after {
    background: #5a9862;
}

.ns-effect-flip .ns-close:hover::before,
.ns-effect-flip .ns-close:hover::after {
    background: #4e8455;
}

.ns-effect-flip.ns-show,
.ns-effect-flip.ns-hide {
    -webkit-animation-name: animFlipFront;
    animation-name: animFlipFront;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

.ns-effect-flip.ns-hide {
    -webkit-animation-name: animFlipBack;
    animation-name: animFlipBack;
}

@-webkit-keyframes animFlipFront {
    0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,-90deg); }
    100% { -webkit-transform: perspective(1000px); }
}

@keyframes animFlipFront {
    0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,-90deg); transform: perspective(1000px) rotate3d(1,0,0,-90deg); }
    100% { -webkit-transform: perspective(1000px); transform: perspective(1000px); }
}

@-webkit-keyframes animFlipBack {
    0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); }
    100% { -webkit-transform: perspective(1000px); }
}

@keyframes animFlipBack {
    0% { -webkit-transform: perspective(1000px) rotate3d(1,0,0,90deg); transform: perspective(1000px) rotate3d(1,0,0,90deg); }
    100% { -webkit-transform: perspective(1000px); transform: perspective(1000px); }
}

/* Bouncy Flip adapted from animate.css by Dan Eden: http://daneden.github.io/animate.css/ */
.ns-effect-bouncyflip {
    -webkit-transform-origin: 50% 0%;
    transform-origin: 50% 0%;
    background: #fff;
    color: #484860;
    top: 0;
    box-shadow: 0 13px 10px -5px rgba(0,0,0,0.2);
}

.ns-effect-bouncyflip .icon {
    position: absolute;
    display: block;
    top: 2em;
    left: 2em;
}

.ns-effect-bouncyflip p {
    padding: 0.5em 0.8em 0.8em 3.5em;
}

.ns-effect-bouncyflip .ns-close::after,
.ns-effect-bouncyflip .ns-close::before {
    background: #484860;
}

.ns-effect-bouncyflip .ns-close:hover::after,
.ns-effect-bouncyflip .ns-close:hover::before {
    background: #545484;
}

.ns-effect-bouncyflip.ns-show,
.ns-effect-bouncyflip.ns-hide {
    -webkit-animation-name: flipInX;
    animation-name: flipInX;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
}

@-webkit-keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -webkit-transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,20deg);
        -webkit-transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -webkit-transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,5deg);
        -webkit-transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
    }
}

@keyframes flipInX {
    0% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-90deg);
        transform: perspective(400px) rotate3d(1,0,0,-90deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
    }

    40% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,20deg);
        transform: perspective(400px) rotate3d(1,0,0,20deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    60% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,-10deg);
        transform: perspective(400px) rotate3d(1,0,0,-10deg);
        -webkit-transition-timing-function: ease-in;
        transition-timing-function: ease-in;
        opacity: 1;
    }

    80% {
        -webkit-transform: perspective(400px) rotate3d(1,0,0,5deg);
        transform: perspective(400px) rotate3d(1,0,0,5deg);
        -webkit-transition-timing-function: ease-out;
        transition-timing-function: ease-out;
    }

    100% {
        -webkit-transform: perspective(400px);
        transform: perspective(400px);
    }
}

.ns-effect-bouncyflip.ns-hide {
    -webkit-animation-name: flipInXSimple;
    animation-name: flipInXSimple;
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
}

@-webkit-keyframes flipInXSimple {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
  }
}

@keyframes flipInXSimple {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -90deg); 
    transform: perspective(400px) rotate3d(1, 0, 0, -90deg);
    -webkit-transition-timing-function: ease-in;
    transition-timing-function: ease-in;
  }
  100% {
    -webkit-transform: perspective(400px);
    transform: perspective(400px);
  }
}

@media screen and (max-width: 25em) {
    .ns-attached {
        left: 30px;
        max-width: none;
        right: 30px;
    }
}