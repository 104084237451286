.feedback-form-authoring-question-container{
    min-height: 200px;
    max-height: 350px;
    overflow-y: scroll;
}
.feedback-form-authoring-question-container div[dnd-list] .dndDraggingSource {
    display: none;
}

.feedback-form-authoring-question-disabled{
    pointer-events: none;
    opacity: 0.8;
}

.feedback-form-empty-question{
    border: 1px solid red;
}
