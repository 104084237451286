.comp-management-radio input[type=radio] {
    display: none;
}

.comp-management-radio label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 30px !important;
    padding-right: 10px;
    padding-bottom: 5px;
}
.comp-management-radio label:before {
    background: none repeat scroll 0 0 #ffffff;
    border: 2px solid #e7ebee ;
    border-radius: 50% ;
    background-clip: padding-box ;
    content: "" ;
    display: inline-block;
    height: 20px ;
    left: 0 ;
    margin-right: 10px;
    position: absolute;
    width: 20px ;
    border-color: #03a9f4;
}
.comp-management-radio input[type=radio]:checked+label:after {
    transform: scale(0.63);
    -webkit-transform: scale(0.63);
    -moz-transform: scale(0.63);
}
.comp-management-radio label:after {
    background-color: #03a9f4;
    border: 1px solid #03a9f4;
    border-radius: 50%;
    background-clip: padding-box;
    content: "";
    display: inline-block;
    font-size: 13px;
    height: 16px;
    left: 2px;
    position: absolute;
    text-align: center;
    top: 2px;
    transform: scale(0);
    width: 16px;
    z-index:1;
}  

@media screen and (max-width: 1400px){
    .steps-container>.steps>li{
        font-size: 14px !important;
        padding: 0 10px 0 20px;
    }

    .steps-container>.steps>li>.badge{
        display: none;
    }
}
.spinner:before {
    background-color: rgba(49, 37, 37, 0.2);
    border-radius: 6px;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    position: fixed;
    content: " ";
}

#maskedWorkPhone::-webkit-input-placeholder { /* WebKit browsers */
    color: #fff;
}

#maskedWorkPhone:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhone::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhone:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #fff;
}

#maskedWorkPhone1::-webkit-input-placeholder { /* WebKit browsers */
    color: #fff;
}

#maskedWorkPhone1:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhone1::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhone1:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #fff;
}

#maskedWorkPhoneSecondary::-webkit-input-placeholder { /* WebKit browsers */
    color: #fff;
}

#maskedWorkPhoneSecondary:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhoneSecondary::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
}

#maskedWorkPhoneSecondary:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #fff;
}

#maskedMobilePhoneSecondary::-webkit-input-placeholder { /* WebKit browsers */
    color: #fff;
}

#maskedMobilePhoneSecondary:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
    color: #fff;
    opacity: 1;
}

#maskedMobilePhoneSecondary::-moz-placeholder { /* Mozilla Firefox 19+ */
    color: #fff;
    opacity: 1;
}

#maskedMobilePhoneSecondary:-ms-input-placeholder { /* Internet Explorer 10+ */
    color: #fff;
}

.scopes {
    font-size: 0.875em;
}

.scopeslabel {
    font-weight: 700;
}

.checkbox {
    padding-left: 4%;
}

.scopesall {
    font-size: 0.75em;
}

.disabledworkflow {
    color: grey;
}

.innerobjects {
    margin-left: 3%;
}

.checkbox-nice input[type=checkbox]:checked + label.disabledworkflow:after {
    opacity: 0.3 !important;
}

.disabledworkflowmessage {
    color: gray;
}

.disablemultiselect {
    background-color: #eee;
    border-color: #e7ebee;
    cursor: not-allowed;
}

.disableindustry {
    background-color: #eee;
    border-color: #e7ebee;
    pointer-events: none;
    cursor: not-allowed;
}

.disablemultiselect button {
    cursor: not-allowed;
    color: #555;
    pointer-events: none;
    border-color: #e7ebee;
}

.validatemultiselect button {
    border-color: red;
}

.industriespanel {
    border-color: red;
}

.intl-tel-input {
    width: 100%;
}

.multiSelect > button {
    width: 100%;
    text-align: left;
}

.multiSelect .checkboxLayer {
    width: 98%;
}

.company-img {
    height: 120px;
    border: 1px solid #eee;
}

input[type="file"] {
    display: none;
}

.profile-img {
    height: 120px;
    border: 1px solid #eee;
}

.custom-file-upload {
    border: 1px solid #ccc;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 4px;
    width: 175px;
}

#user-profile {
    margin-top: 25px;
}

.licensePreferenceMessage {
    font-size: 0.9em;
}

.licensePreferenceName {
    font-size: 14px;
}

#company-picture {
    margin-top: 25px;
}

.lbl {
    max-width: 50%;
    height: 22px !important;
    /*		margin-left: 44px !important;*/
    border: 0px;
}

.lbl .onoffswitch-inner:before {
    line-height: 26px !important;
    font-size: 11px !important;
    margin-top: -2px;
    background-color: #03a9f4 !important;
}

.lbl .onoffswitch-inner:after {
    line-height: 26px !important;
    font-size: 10px !important;
    background-color: lightgray;
    margin-top: -1px;
    padding-right: 6px !important;
}

.swch {
    width: 7px !important;
    bottom: 6px !important;
    border-color: white;
    margin-right: 5%;
}

.swch:before {
    right: 0px !important;
}

.swch:after {
    right: 26px !important;
}

.disableelementview {
    color: #9e9e9e !important;
    opacity: 1;
    cursor: auto;
}

.nopointerevents {
    pointer-events: none;
}

.disabledworkflow1 {
    opacity: 0.4;
}

.selected-plan {
    background: lightgoldenrodyellow;
    box-shadow: 0 4px 20px 0 lightblue, 0 -4px 20px 0 lightblue !important;
}

.pricing-package:hover .pricing-card {
    background: lavender;
}

.pricing-package:hover .selected-plan {
    background: lightgoldenrodyellow;
    color: #212121;
    box-shadow: 0 4px 20px 0 lightblue, 0 -4px 20px 0 lightblue !important;
}

.plan-button {
    display: inline;
}

.pricing-package .package-footer .btn-secondary:hover > span {
    color: black;
}

.upgraded-plan {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid green;
    color: green;
}

.downgraded-plan {
    margin-top: 20px;
    padding: 20px;
    border: 1px solid darkorange;
    color: darkorange;
}

.slider-icons-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: 300px;
    z-index: 1;
}

.slider-icon {
    font-size: 30px;
}

.hide-plan-button {
    visibility: hidden;
}

.plan-content {
    display: inline-block;
    margin-right: 10px;
}

.plans-container {
    float: left;
    width: 99%;
    margin-left: 5px;
    overflow: hidden;
    white-space: nowrap;
}

.justify-content-center {
    display: flex;
    justify-content: center
}

.contact-details-popover {
    max-width: 400px;
    width: 400px;
    font-size: 13px;
}

.company-management-cancel-button-container {
    display:flex; 
    justify-content: flex-end
} 

.only-delete-icon {
    /* float:left; */
} 

.credential-note{
    color:gray;
    display:inline-block;
    margin-left:10px;
}

.inline-input{
    display:inline;
    border-color:#e7ebee; 
    border-radius:3px; 
    text-align:center;
}
.score-matching-setting{
    margin-top:13px;
    border-bottom: 1px solid lightgrey;
    padding: 10px 0;
    padding-left: 8px;
}

.company-branding-block {
    transition: all linear 500ms;
    opacity: 1;
}

.company-branding-block.ng-hide {
    opacity: 0;
}
.ng-hide {
    opacity: 0;
    transition: none 0;
}

.user-configuration-table tbody>tr>td{
    vertical-align: top!important;
}

.password-expiry-duration{
    display: inline-block;
    width:110px;
}

@media screen and (max-width: 1380px) {
    .password-expiry-duration{
        width: 92px;
    }
}