.ns-type-error {
	background-color: #e74c3c;
	color: #fff;
}
.ns-type-error a {
	color: #fff;
}
.ns-type-error .ns-close:before, 
.ns-type-error .ns-close:after {
	background-color: #fff;
}

.ns-type-warning {
	background-color: #f1c40f;
	color: #fff;
}
.ns-type-warning a {
	color: #fff;
}
.ns-type-warning .ns-close:before, 
.ns-type-warning .ns-close:after {
	background-color: #fff;
}

.ns-type-notice {
	background-color: #01b9fe;
	color: #fff;
}
.ns-type-notice a {
	color: #fff;
}
.ns-type-notice .ns-close:before, 
.ns-type-notice .ns-close:after {
	background-color: #fff;
}

.ns-type-success {
	background-color: #2ecc71;
	color: #fff;
}
.ns-type-success a {
	color: #fff;
}
.ns-type-success .ns-close:before, 
.ns-type-success .ns-close:after {
	background-color: #fff;
}

.ns-effect-loadingcircle .ns-close:before, 
.ns-effect-loadingcircle .ns-close:after {
	background-color: #ddd;
}

.shape-progress svg,
.notification-shape {
	pointer-events: none;
}
.ns-effect-loadingcircle {
	background-color: #fff !important;
}