.job-match-disabled {
    background-color: lightgrey !important;
}

.job-match-score {
    /*background: green;*/
    color: #fff;
    border-radius: 5px;
    padding: 4px;
    display: inline-block;
    width: 45px;
}

#candidate-possible-job-match_paginate .pagination li {
    padding: 0px !important;
}

#candidate-possible-job-match_paginate .pagination li:hover{
    background-color: none !important;
    background: none !important;
    border: 1px solid transparent !important;
}

.possible-job-matches-primary-hiring-manager-icon {
    font-size: 12px;
    position: relative;
    bottom: 5px;
    color: #03a9f4;
    font-family: cursive;
    font-weight: 600;
}

.possible-job-match-shared-requisition-color {
   color:  #7541e0 !important;
}

.possible-job-match-job-vendor-recruiter {
    color: #7541e0;
}