.review-candidate-pull-associate-candidate-selection-bg{
    background-color: #e8a540 !important;
}

.review-candidate-pull-review-table-row {
    margin-top: 0.2em;
    border-top: 1px dotted;
    padding: 1em;
}

.review-candidate-pull-review-table-header-text {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875em;
    font-weight: 600;
}

.review-candidate-pull-review-table-header-row{
    padding: 0.2em 1em 0.2em 1em;
}

.review-candidate-pull-counts-row{
    margin-top: 20px;
    margin-bottom: 20px;
}

.review-candidate-pull-counts-heading{
    font-size: 1.2em;
    padding: 10px 0px 0px 40px;
}

.review-candidate-pull-review-heading-container{
    padding: 5px 15px 15px 15px;
}

.review-candidate-pull-modal > .modal-dialog{
    width: 75%;
}

.review-candidate-pull-modal > .modal-dialog > .modal-content{
    margin-top: 1%;
}

.light-green-bg {
    background-color: #8bc34a99;
}