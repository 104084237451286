.header-nav {
    display: flex;
    justify-content: space-between;
    height: 60px;
    line-height: 60px;
    max-width: 95%;
    margin: auto;
}

.logo-and-notification-container {
    display: flex;
    justify-content: flex-start;
    flex: 1;
}

.header-company-name {
    font-size: 1.3em;
    display: inline;
    margin: auto 0;
    flex: 1
}

.header-profile-and-logout-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.open-sidenav {
    display: block;
}

.close-sidenav {
    display: none;
}