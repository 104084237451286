.wizard .wizard-inner {
  position: relative;
  overflow: hidden;
  background-color: #f9f9f9;
  border: 1px solid #d4d4d4;
  *zoom: 1;
}
.wizard .wizard-inner:before, .wizard .wizard-inner:after {
  display: table;
  line-height: 0;
  content: "";
}
.wizard .wizard-inner:after {
  clear: both;
}
.wizard .wizard-inner ul {
  width: 4000px;
  padding: 0;
  margin: 0;
  list-style: none outside none;
}
.wizard .wizard-inner ul li {
  position: relative;
  float: left;
  height: 46px;
  padding: 0 20px 0 30px;
  margin: 0;
  font-size: 16px;
  line-height: 46px;
  color: #999999;
  cursor: default;
  background: #ededed;
}
.wizard .wizard-inner ul li .chevron {
  position: absolute;
  top: 0;
  right: -14px;
  z-index: 1;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #d4d4d4;
}
.wizard .wizard-inner ul li .chevron:before {
  position: absolute;
  top: -24px;
  right: 1px;
  display: block;
  border: 24px solid transparent;
  border-right: 0;
  border-left: 14px solid #ededed;
  content: "";
}
.wizard .wizard-inner ul li.complete {
  color: #468847;
  background: #f3f4f5;
}
.wizard .wizard-inner ul li.complete:hover {
  cursor: pointer;
  background: #d9edf7;
}
.wizard .wizard-inner ul li.complete:hover .chevron:before {
  border-left: 14px solid #d9edf7;
}
.wizard .wizard-inner ul li.complete .chevron:before {
  border-left: 14px solid #f3f4f5;
}
.wizard .wizard-inner ul li.active {
  color: #3498db;
  background: #d9edf7;
}
.wizard .wizard-inner ul li.active .chevron:before {
  border-left: 14px solid #d9edf7;
}
.wizard .wizard-inner ul li .badge {
  margin-right: 8px;
}
.wizard .wizard-inner ul li:first-child {
  padding-left: 20px;
  border-radius: 4px 0 0 4px;
}
.wizard .wizard-inner .actions {
  position: absolute;
  right: 0;
  z-index: 1000;
  float: right;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 46px;
  vertical-align: middle;
  background-color: #e5e5e5;
  border-left: 1px solid #d4d4d4;
}
.wizard .wizard-inner .actions a {
  margin-right: 8px;
  font-size: 12px;
  line-height: 45px;
}
.wizard .wizard-inner .actions .btn-prev i {
  margin-right: 5px;
}
.wizard .wizard-inner .actions .btn-next i {
  margin-left: 5px;
}
.wizard .step-content {
  clear: both;
}
.wizard .step-content .step-pane {
  display: none;
}
.wizard .step-content .active {
  display: block;
}
.wizard .step-content .active .btn-group .active {
  display: inline-block;
}

.rtl .wizard .wizard-inner .actions {
  float: left;
  left: 0;
  right: auto;
}
.rtl .wizard .wizard-inner ul {
  width: auto;
  float: right;
}
.rtl .wizard .wizard-inner ul li {
  float: right;
}
.rtl .wizard .wizard-inner ul li .badge {
  margin-right: 0;
  margin-left: 8px;
}
.rtl .wizard .wizard-inner ul li .chevron {
  right: auto;
  left: -14px;
  border-color: transparent #d4d4d4 transparent transparent;
  border-style: solid solid solid none;
  border-width: 24px 14px 24px 0;
}
.rtl .wizard .wizard-inner ul li .chevron:before {
  left: 1px;
  right: auto;
  border-width: 24px 14px 24px 0;
  border-color: transparent #ededed transparent transparent;
  border-style: solid solid solid none;
}
.rtl .wizard .wizard-inner ul li:first-child {
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 0 4px 4px 0;
}
.rtl .wizard .wizard-inner ul li.active .chevron:before {
  border-right: 14px solid #d9edf7;
  border-left-color: transparent;
}
.rtl .wizard .wizard-inner ul li.complete .chevron:before {
  border-right: 14px solid #f3f4f5;
}
