.project-img-owner {
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    display: block;
    /*float: left;*/
    height: 33px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    width: 33px;
}

.filter-symbol-div-margin {
    margin: 10px;
}

/* <!--.recruiter-image {-->
    <!--margin-bottom: 5px;-->
<!--}--> */

.recruiterpopup {
    display: inline-block !important;
    /*width : 20% !important;*/
    float: right !important;
}

.clearfix .popover {
    /* <!--width: 60%;--> */
    max-width: none;
    white-space: nowrap;
}

/* <!--.recruiter-image-resize {-->
    <!--width: 50% !important;-->
    <!--height: 19% !important;-->
<!--}--> */

#filterSearchRequisitions {
    margin-top: 88px;
}

@media (max-width: 1431px) {
    #reportrange {
        background: #fff !important;
        cursor: pointer !important;
        padding: 7px 10px !important;
        border: 1px solid #ccc !important;
        width: 100% !important;
        height: 34px !important;
        display: block !important;
        font-size: 12px !important;
    }
}

.req-num {
    color: white;
    padding-left: 15px;
}

.req-date {
    color: white;
    padding-left: 5px;
}

.project-box .project-box-header .name a {
    padding: 0px 15px;
}

.project-box .project-box-content {
    padding: 6px 20px 5px;
    text-align: center;
}

.project-box .project-box-footer a {
    border-right: 1px solid #eee;
    color: #212121;
    display: block;
    float: left;
    margin: 0px 0 !important;
    padding: 0px 10px !important;
    text-align: center;
    width: 33.33%;
}


@media screen and (max-width: 1387px) and (min-width: 1288px) {
    .recruiter-count {
        position: absolute;
        right: 5%;
        bottom: 105px;
        border-radius: 100px;
        padding: 3px 3px 3px 3px;
        text-align: center;
        font-weight: normal;
        font-size: 14px;
        line-height: 1;
        margin-right: 15px;
        margin-top: -65px;
    }
}

.recruiter-count .popover {
    top: 181.5px !important;
    left: 265.109px !important;
}

.dropdown-menu-action {
    min-width: 0px !important;
}

.status {
    color: #212121 !important;
    border: none !important;
    background: transparent;
    font-size: 14px;
    line-height: 1.7;
    padding-left: 28px !important;
    transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
    width: 100%;
    text-align: left !important;
    padding: 1px 28px !important;
}

.action-drop-disabled {
    opacity: .45 !important;
}

.dropdown-menu>li>a {
    font-size: 14px !important;
}

.updateReqtextcolor {
    color: #03a9f4;
}

.custom-modal-header {
    padding: 15px !important;
    border-bottom: 1px solid #e5e5e5;
}

.modal-body-font-size {
    font-size: 14px;
}

.requisition_not_found {
    height: 187px;
    margin-bottom: 0px;
    text-align: center;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
}

.multiSelect>button {
    width: 100% !important;
    text-align: left !important;
}

/* .multiSelect-button {
    <!--border: 1px solid #c6c6c6;-->
    <!--padding: 1px 8px 1px 8px;-->
    <!--min-height: 38px !important;-->
    <!--border-radius: 4px;-->
    <!--color: #555;-->
    <!--user-select: none;-->
    <!--white-space: normal;-->
    <!--background-color: #fff;-->
    <!--background-image: linear-gradient(#fff, #f7f7f7);-->
    <!--width: 295px !important;-->
    <!--text-align: left;-->
} */
.dropdown-menu-form {
    width: 100%;
}

.daterangeelement {
    height: 38px;
    font-size: 13px;
    padding-left: 10px;
    color: #555;
}

.inputBoxBorder {
    border: 1px solid #ccc;
}

.form-control {
    border-radius: 3px;
    background-clip: padding-box;
    border-color: #e7ebee;
    border-width: 2px;
    box-shadow: none;
    font-size: 14px;
}

.dropdown-width {
    width: 100%;
}

.requisition-visibility-filters-container {
    margin: 10px;
    margin-top: 20px;
    font-size: 0.9em
}

.requisition-filter-close-icon {
    top: -11px;
    right: 11px;
    border-radius: 50%;
    color: #e84e40;
    background-color: white;
    border: none;
}

.requisition-selected-filter {
    width: fit-content;
    background-color: #03a9f4;
    color: white;
}

.requisition-card-row-view-head {
    background-color: #4a9be0;
    box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
}

@media screen and (max-width: 1000px) {
    .requisition-card-row-view-head {
        font-size: 12px;
    }
}

@media screen and (min-width: 1000px) {
    .requisition-card-row-view-head {
        font-size: 14px;
    }
}

@media screen and (max-width: 1300px) {
    .requisition-card-row-view-head-req-updated {
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
    }
}