
.add-vendor-button {
	color: #fff !important;
	background-color: #03a9f4 !important;
	border-color: #2980b9 !important;
	background-image: none !important;
	margin-top: 10px;
	margin-bottom: 15px;
	margin-right: 20px;
}
