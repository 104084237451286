.panel-title>a {
    color: black !important;
    text-decoration: none !important;
}

.no_history_found {
    height: 187px;
    margin-bottom: 0px;
    font-size: 24px;
    color: gray;
    padding-top: 50px;
}