.theme-whbl .btn-danger {
    background-color: #e84e40;
    border-color: #dd191d;
}

.theme-whbl .btn-danger:hover,
.theme-whbl .btn-danger:focus,
.theme-whbl .btn-danger:active,
.theme-whbl .btn-danger.active,
.theme-whbl .open .dropdown-toggle.btn-danger {
    background-color: #e84e40;
    border-color: #dd191d;
}

.infographic-box .headline {
    white-space: nowrap;
    font-size: 1.5em !important;
}

@media screen and (max-width: 1400px) {
    .infographic-box .headline {
        font-size: 16px !important;
    }
} 

.disable-element {
	pointer-events : none !important;
    opacity : 0.6 !important;
    cursor: default;
}

.notes-popover>.popover-inner {
    background-color: #add8e66e;
}

.notes-popover.top>.arrow::after {
    border-top-color: #deecf0;
}

.notes-popover .popover-content {
    color: black;
}

.notes-icon {
    color: darkgray;
}

#header-navbar .nav>li>a>span.big-count {
    background: none repeat scroll 0 0 #e84e40;
    border-radius: 50%;
    background-clip: padding-box;
    /* stops bg color from leaking outside the border: */
    color: #fff;
    display: block;
    font-size: 9px;
    height: 14px;
    position: absolute;
    right: 4px;
    text-align: center;
    top: 12px;
    width: 21px;
    line-height: 1.6;
} 

.candidate-state-wrapper ul {
    padding-left: 5px;
    padding-right: 5px;
}

.dropdown-menu > li.orange-bg > a:hover {
    background-color: orange !important;
    text-decoration: none;
}

#theme-wrapper {
    max-width: 100%;
}

.input-group-addon {
    color:black !important;
    background-color: #fff !important;
}

.tooltip-inner {
    max-width: 300px;
}